import { takeLatest, all, call, put } from "typed-redux-saga";

import { apiRequest } from "lib/api/apiClient";
import { ACTIVITY_ACTION_TYPES } from "./activity.types";
import { activityActions } from "./activity.slice";
import { getFromCookieProtected } from "utils/helper/helper";
import { AccessToken } from "utils/types/user";
import { AUTH_TOKEN } from "utils/helper/states";

function* fetchPolicies() {
  const token = getFromCookieProtected<AccessToken>(AUTH_TOKEN);
  if (token == null) {
    return;
  }
  yield* put(activityActions.setIsLoading(true));
  try {
    const link = "/api/v1/activity";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(activityActions.setAllActivity(data));
  } catch (error) {
    console.log(error as Error);
  } finally {
    yield* put(activityActions.setIsLoading(false));
  }
}

export function* onFetchData() {
  yield* takeLatest(ACTIVITY_ACTION_TYPES.FETCH_ALL_DATA, fetchPolicies);
}

export function* activitySagas() {
  yield* all([call(onFetchData)]);
}
