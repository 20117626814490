import { Button, Card, CardBody, CardFooter } from "@nextui-org/react";
import NextUIImage from "components/image/next-ui-image";
import { SecondaryNextArrow, SecondaryPrevArrow } from "components/slider";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { selectPolicies } from "store/data/data.selector";
import {
  classNames,
  flattenItems,
  groupItemsInTwos,
} from "utils/helper/helper";

const settings: Settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3500,
  nextArrow: <SecondaryNextArrow />,
  prevArrow: <SecondaryPrevArrow />,
};
function PoliciesLG() {
  const sliderRef = useRef<Slider | null>(null);
  const policies = useSelector(selectPolicies);
  const flatItems = policies ? flattenItems(policies) : [],
    rootPolicies = flatItems.filter((p) => !p.hasChildren),
    grouped = groupItemsInTwos(rootPolicies);

  const next = () => {
    if (sliderRef.current) sliderRef.current.slickNext();
  };
  const previous = () => {
    if (sliderRef.current) sliderRef.current.slickPrev();
  };

  return (
    <div id="policies-lg">
      <Slider
        ref={sliderRef}
        {...settings}
        className="">
        {grouped.map((item, index: number) => (
          <div
            key={index}
            className="w-full h-full space-y-12 px-4 pb-4">
            {item.map((policy) => (
              <Card
                shadow="sm"
                className="min-w-full h-full">
                <CardBody className="overflow-visible p-0">
                  <Link to={`/policy/${policy.slug}`}>
                    <NextUIImage
                      shadow="none"
                      radius="none"
                      width="100%"
                      alt={policy.title}
                      className="w-full object-cover aspect-[370/228]"
                      src={policy.img}
                    />
                  </Link>
                </CardBody>
                <CardFooter className="text-small justify-start items-start h-20">
                  <Link
                    to={`/policy/${policy.slug}`}
                    className="text-primary text-sm lg:text-xl font-semibold uppercase">
                    {policy.title}
                  </Link>
                </CardFooter>
              </Card>
            ))}
          </div>
        ))}
      </Slider>

      <div className="w-full centered gap-4 pt-4">
        <Button
          className={classNames("w-10 h-10 bg-white text-primary")}
          variant="bordered"
          color="secondary"
          onClick={previous}>
          Prev
        </Button>
        <Button
          className={classNames("w-10 h-10 bg-white text-primary")}
          variant="bordered"
          color="secondary"
          onClick={next}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default PoliciesLG;
