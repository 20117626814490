import CustomSelect from "components/form/form-select.component";
import { selectAttributes } from "store/attribute/attribute.selector";
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiValue } from "react-select";
import AttributeValue from "./attribute-values.component";
import {
  selectProduct,
  selectRelatedCategoryData,
} from "store/product/product.selector";
import { productActions } from "store/product/product.slice";

type Props = {
  setHasAttribute: Dispatch<SetStateAction<boolean>>;
};
function AddAttributes({ setHasAttribute }: Props) {
  const product = useSelector(selectProduct),
    dispatch = useDispatch();
  const relatedData = useSelector(selectRelatedCategoryData),
    relatedAttributes = relatedData?.attributes.map((i) => i.id) || [];
  const _attributes = useSelector(selectAttributes) || [],
    attributes = _attributes.filter((i) => relatedAttributes.includes(i.id)),
    options = !attributes
      ? []
      : attributes.map((attribute) => ({
          label: attribute.name,
          value: attribute.id,
        })),
    selectedAttributeIds = product.attributes,
    selected = options.filter((option) =>
      selectedAttributeIds.includes(option.value)
    ),
    selectedAttributes = attributes?.filter((option) =>
      selectedAttributeIds.includes(option.id)
    );

  const handleSelect = (
      option: MultiValue<{
        label: string;
        value: number;
      }>
    ) => {
      const optionValues = option.map((i) => i.value);
      dispatch(
        productActions.updateProductData({
          name: "attributes",
          value: optionValues,
        })
      );
    },
    setAttributes = (arr: number[]) =>
      dispatch(
        productActions.updateProductData({
          name: "attributes",
          value: arr,
        })
      ),
    handleCancelAttribute = () => {
      dispatch(
        productActions.updateProductData({
          name: "attributes",
          value: [],
        })
      );
      setHasAttribute(false);
    };

  return (
    <div>
      <div>
        <div className="label">
          <span className="label-text">Attributes</span>
        </div>
        <div className="flex gap-2">
          <CustomSelect
            name="attributes[]"
            options={options}
            value={selected}
            onChange={(option) => handleSelect(option)}
            className="w-full h-full"
            isSearchable
            isMulti
          />
          <button
            className="btn btn-square btn-soft-danger"
            onClick={handleCancelAttribute}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-3">
        {selectedAttributes?.map((attribute) => (
          <AttributeValue
            attribute={attribute}
            setAttributes={setAttributes}
            key={attribute.id}
          />
        ))}
      </div>
    </div>
  );
}

export default AddAttributes;
