import React from "react";

type Props = {
  isOpen: boolean;
};
const BurgerMenu: React.FC<Props> = ({ isOpen }) => {
  return (
    <div
      className="burger-menu w-8 p-2 gap-1"
      role="button">
      <div className={`bar ${isOpen ? "open" : ""}`}></div>
      <div className={`bar ${isOpen ? "open" : ""}`}></div>
      <div className={`bar ${isOpen ? "open" : ""}`}></div>
    </div>
  );
};

export default BurgerMenu;
