import { createSelector } from "reselect";
import { RootState } from "../store";

const selectAttributeReducer = (state: RootState) => state.attribute;

export const selectAttributes = createSelector(
  [selectAttributeReducer],
  (data) => data.all
);

export const selectAttributeByID = (id: number) =>
  createSelector([selectAttributeReducer], (data) =>
    data.all?.find((i) => i.id === id)
  );

export const selectAttributeLoading = createSelector(
  [selectAttributeReducer],
  (data) => data.loading
);
