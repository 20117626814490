import {
  Accordion,
  AccordionItem,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { ChevronDown, ChevronRight } from "assets/svgs/chevron-svg";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { selectPolicies } from "store/data/data.selector";
import { classNames, goToTop } from "utils/helper/helper";

function PoliciesDropdown({ toRight = true }) {
  const [open, setOpen] = useState(false);
  const policies = useSelector(selectPolicies);
  const location = useLocation();

  const isActive = location.pathname.startsWith("policy");
  if (policies == null) return <></>;
  return (
    <Dropdown onOpenChange={(state) => setOpen(state)} closeOnSelect={false}>
      <DropdownTrigger>
        <span
          role="button"
          className={classNames(
            "flex gap-2 items-center text-base font-normal",
            toRight && (open || isActive) ? "text-secondary" : "text-primary"
          )}
        >
          <span className="capitalize">Selling on cusorcart!</span>
          <ChevronDown
            className={classNames(
              open && "rotate-180",
              "transition-transform duration-300"
            )}
          />
        </span>
      </DropdownTrigger>
      <DropdownMenu aria-label="Dynamic Actions" items={policies || undefined}>
        {(item) =>
          item.children.length > 0 ? (
            <DropdownItem
              key={item.id}
              color={"default"}
              className="text-dark-base"
            >
              <div
                className={classNames(
                  "dropdown dropdown-hover w-full",
                  toRight && "dropdown-right"
                )}
              >
                <div
                  tabIndex={0}
                  role="button"
                  className="flex gap-2 items-center justify-between text-base font-normal w-full"
                >
                  {item.title}
                  <ChevronRight />
                </div>
                <ul
                  tabIndex={0}
                  className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow"
                >
                  {item.children.map((policy) => (
                    <li key={policy.id}>
                      <Link to={`/policy/${policy.slug}`} onClick={goToTop}>
                        {policy.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </DropdownItem>
          ) : (
            <DropdownItem key={item.id} color={"default"}>
              <Link
                to={`/policy/${item.slug}`}
                onClick={goToTop}
                className="block w-full h-full text-dark-base text-base font-normal"
              >
                {item.title}
              </Link>
            </DropdownItem>
          )
        }
      </DropdownMenu>
    </Dropdown>
  );
}

export function PoliciesAccordion({
  handleClick,
}: {
  handleClick: () => void;
}) {
  const policies = useSelector(selectPolicies) || [];

  return (
    <Accordion className="px-0 policies-accordion">
      <AccordionItem
        classNames={{
          title: "text-primary text-small",
          indicator: "text-primary",
          trigger: "py-1",
          content: "px-0",
        }}
        title="Selling on cusorcart!"
      >
        <div className="">
          <Accordion>
            {policies.map((item) =>
              item.children.length > 0 ? (
                <AccordionItem
                  classNames={{
                    title: "text-primary text-small",
                    indicator: "text-primary",
                    trigger: "py-2",
                  }}
                  key={item.id}
                  title={item.title}
                >
                  <div className="pb-2">
                    <ul tabIndex={0} className="menu bg-base-100">
                      {item.children.map((policy) => (
                        <li key={policy.id}>
                          <Link
                            to={`/policy/${policy.slug}`}
                            onClick={handleClick}
                          >
                            {policy.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </AccordionItem>
              ) : (
                <AccordionItem
                  classNames={{
                    title: "text-primary text-small",
                    indicator: "text-primary hidden",
                    trigger: "py-2",
                  }}
                  title={
                    <Link to={`/policy/${item.slug}`} onClick={handleClick}>
                      {item.title}
                    </Link>
                  }
                  hasChildItems={false}
                />
              )
            )}
          </Accordion>
        </div>
      </AccordionItem>
    </Accordion>
  );
}

export default PoliciesDropdown;
