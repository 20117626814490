import { Card, CardBody, CardHeader } from "@nextui-org/react";
import CustomSelect from "../../form/form-select.component";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct } from "store/product/product.selector";
import { updateCurrentProduct } from "store/product/product.actions";

function AddProductStatus() {
  const product = useSelector(selectProduct),
    dispatch = useDispatch();
  const handleChange = (target: { value: number; label: string }) => {
    const { value } = target;
    dispatch(
      updateCurrentProduct({
        name: "published",
        value: value,
      })
    );
  };
  const options = [
      { label: "Draft", value: 0 },
      { label: "Published", value: 1 },
    ],
    selected = options.find((i) => i.value === product.published);

  return (
    <Card
      className="py-4 lg:px-4 overflow-visible shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Status </h4>
      </CardHeader>
      <CardBody className="overflow-visible py-4 space-y-2">
        <CustomSelect
          label="Product Status"
          value={selected}
          options={options}
          onChange={(value) => value != null && handleChange(value)}
        />
      </CardBody>
    </Card>
  );
}

export default AddProductStatus;
