import { createSelector } from "reselect";
import { RootState } from "../store";

const selectCategoryReducer = (state: RootState) => state.category;

export const selectAllCategories = createSelector(
  [selectCategoryReducer],
  (data) => data.all
);

export const selectCategorySearchValue = createSelector(
  [selectCategoryReducer],
  (data) => data.searchValue
);
export const selectCategoryLoading = createSelector(
  [selectCategoryReducer],
  (data) => data.loading
);
export const selectCategoryByID = (id: number) =>
  createSelector([selectCategoryReducer], (data) =>
    data.all?.find((c) => c.id === id)
  );
