import { classNames } from "utils/helper/helper";
import { mainURL } from "utils/helper/links";
import { PiGlobeSimpleThin } from "react-icons/pi";
// import LanguageCurrency from "./lang-cur.component";
import DarkModeToggle from "../../toolkit/theme-toggle.component";
import HeaderNotification from "./notification.component";
import HeaderUserDropdown from "./user-dropdown.component";
import SideDrawer from "./drawer.component";
import SideBarSmToggle from "./sm-toggle.component";
import { Button } from "@nextui-org/react";

function Header() {
  return (
    <header
      id="page-topbar"
      className="fixed header top-0 z-30 lg:ml-64 inset-x-0 bg-white drop-shadow-1 dark:bg-primary-950 dark:drop-shadow-none"
    >
      <div
        className={classNames(
          "flex items-center px-4 border-b dark:border-dark-border dark:shadow-none w-full"
        )}
      >
        <div className="flex items-center justify-between w-full py-3">
          <div className="flex items-center gap-2">
            <Button
              isIconOnly
              variant="light"
              as={"a"}
              href={mainURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PiGlobeSimpleThin size={25} className="dark:text-white" />
            </Button>
            <SideBarSmToggle />
            <SideDrawer />
          </div>

          <div className="flex items-center gap-2">
            {/* <LanguageCurrency /> */}
            <DarkModeToggle />
            <HeaderNotification />
            <HeaderUserDropdown />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
