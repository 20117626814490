import { classNames } from "utils/helper/helper";
import { Spinner } from "@nextui-org/react";
import { BiCheck } from "react-icons/bi";

function AddButton({
  text = "Save Changes",
  loading = false,
}: {
  text?: string;
  loading?: boolean;
}) {
  const isLoading = loading;
  return (
    <button
      className={classNames(
        "btn btn-primary text-dark-base",
        isLoading && "!opacity-50"
      )}
      type="submit"
      disabled={isLoading}>
      {isLoading ? <Spinner size="sm" /> : <BiCheck size={20} />}
      {text}
    </button>
  );
}

export default AddButton;
