import { Link } from "react-router-dom";
import { authLinks } from "./links";

export const faqArray = [
  {
    id: 1,
    title: "How do I sign up as a vendor on Cusorcart?",
    content: (
      <p>
        To get started, simply visit our vendor{" "}
        <Link
          to={authLinks.register}
          className="text-secondary underline">
          registration page
        </Link>
        , fill in your details, and submit your application. Once completed, our
        team will review your submission and guide you through the approval
        process.
      </p>
    ),
  },
  {
    id: 2,
    title: "What are the requirements to become a Cusorcart vendor?",
    content: (
      <p>
        To become a vendor, you need to provide accurate business details, valid
        identification, and a list of the products you intend to sell. Vendors
        must meet our quality and authenticity standards to be approved.
      </p>
    ),
  },
  {
    id: 3,
    title: "How long does the vendor approval process take?",
    content: (
      <p>
        The approval process usually takes between 2 to 5 business days. We
        review your application, verify your business, and ensure you meet our
        criteria before granting access to our platform.
      </p>
    ),
  },
  {
    id: 4,
    title: "Do I have to pay to sell on Cusorcart as a vendor?",
    content: (
      <p>
        No, Cusorcart allows you to sell online for free. There are no
        registration fees. However, there may be minimal commission charges on
        successful sales to maintain platform operations and marketing support.
      </p>
    ),
  },
  {
    id: 5,
    title:
      "What kind of support does Cusorcart offer during the onboarding process?",
    content: (
      <p>
        We offer training resources, step-by-step guides, and a dedicated
        support team to assist you throughout the onboarding process. Our Help
        Center also provides videos, blogs, and FAQs to make your experience
        smooth and straightforward.
      </p>
    ),
  },
  {
    id: 6,
    title: "How do I set up my store on Cusorcart?",
    content: (
      <p>
        After completing the registration and approval process, you’ll be guided
        through setting up your store. This includes creating your vendor
        profile, uploading product images, and setting prices. Detailed
        instructions and tutorials are available.
      </p>
    ),
  },
  {
    id: 7,
    title: "What types of products can I sell on Cusorcart?",
    content: (
      <p>
        Cusorcart allows vendors to sell a wide range of products, including
        electronics, fashion, beauty products, home goods, and more. Products
        must comply with our quality and authenticity standards.
      </p>
    ),
  },
  {
    id: 8,
    title: "What are the commission rates and fees for selling on Cusorcart?",
    content: (
      <p>
        Cusorcart charges a competitive commission rate on each sale. The rate
        may vary based on product categories and promotions. Full details are
        provided during onboarding and are also available in the vendor
        dashboard.
      </p>
    ),
  },
  {
    id: 9,
    title: "How do I receive payments for sales made on Cusorcart?",
    content: (
      <p>
        Payments are made directly to your bank account. Payment schedules and
        payout timelines are shared in our vendor policies. We ensure secure
        transactions and timely disbursements.
      </p>
    ),
  },
  {
    id: 10,
    title: "What delivery and logistics options are available?",
    content: (
      <p>
        Cusorcart partners with reputable logistics companies to handle
        deliveries. You can choose from standard, expedited, or same-day
        delivery options. Vendors have the flexibility to manage their own
        shipping or use our integrated logistics services.
      </p>
    ),
  },
  {
    id: 11,
    title: "What happens if a customer requests a return or refund?",
    content: (
      <p>
        Cusorcart’s return and refund policy is designed to be fair for both
        customers and vendors. We handle the return process, and you’ll be
        notified if a product is eligible for a return. The relevant amount will
        be deducted from your payout.
      </p>
    ),
  },
  {
    id: 12,
    title: "What kind of training is provided for new vendors?",
    content: (
      <p>
        We offer a comprehensive training program that includes video tutorials,
        webinars, and an interactive guide. These resources cover everything
        from store setup to marketing strategies, helping you make the most of
        our platform.
      </p>
    ),
  },
  {
    id: 13,
    title: "How can I promote my products on Cusorcart?",
    content: (
      <p>
        Cusorcart provides various marketing tools, including sponsored
        listings, featured promotions, and discounts. Our vendor dashboard
        offers insights and recommendations for boosting your store’s visibility
        and sales.
      </p>
    ),
  },
  {
    id: 14,
    title: "What are Cusorcart’s policies on product listing and quality?",
    content: (
      <p>
        Our platform prioritizes authenticity and quality. All listed products
        must meet our quality control guidelines, which include accurate
        descriptions, clear images, and fair pricing. Failure to comply may
        result in penalties or removal from the platform.
      </p>
    ),
  },
  {
    id: 15,
    title: "Where can I find more information or get help if I’m stuck?",
    content: (
      <p>
        Our Help Center is available 24/7 with self-service resources, including
        FAQs, videos, and detailed guides. For technical assistance, visit our
        Help Center.
      </p>
    ),
  },
];
