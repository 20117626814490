import FormInput from "components/form/form-input.component";
import { updateCurrentProduct } from "store/product/product.actions";
import { selectProduct } from "store/product/product.selector";
import { Combination } from "utils/types/products";
import { ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";

function AddVariations() {
  const product = useSelector(selectProduct);
  const combinations: Combination[] = product.variations || [],
    dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: number) => {
    const { name, value } = e.target;
    const newVariations = combinations.map((spec, i) =>
      i === key ? { ...spec, [name]: Number(value) } : spec
    );
    console.log(name, value, key);
    dispatch(
      updateCurrentProduct({
        name: "variations",
        value: newVariations,
      })
    );
  };

  return (
    <div className="space-y-3 divide-y divide-dashed dark:divide-gray-500">
      {combinations.map((combination, index) => (
        <div key={index}>
          <div className="label">
            <span className="label-text">{combination.combination_name}</span>
          </div>
          <div className="grid grid-cols-2 gap-3">
            <FormInput
              type="number"
              lang="en"
              min="0"
              step="0.01"
              name="price"
              label="Price"
              value={combination.price}
              onChange={(e) => handleChange(e, index)}
              placeholder={"Type Price here. . ."}
              required
            />
            <FormInput
              type="number"
              lang="en"
              min="0"
              name="stock"
              label={`Quantity`}
              value={combination.stock}
              onChange={(e) => handleChange(e, index)}
              placeholder={`Type variant quantity here. . .`}
              required
            />
          </div>
        </div>
      ))}
    </div>
  );
}

export default AddVariations;
