import { Activity } from "utils/types/activity";
import NotificationIcon from "./notification-icon";
import { Link } from "react-router-dom";
import { timeAgo } from "utils/helper/helper";

function NotificationItem({ item }: { item: Activity }) {
  const content = (
    <>
      <NotificationIcon type={item.type} />

      <div className="w-full pl-3 overflow-hidden">
        <p className="text-gray-500 text-sm mb-1.5 dark:text-gray-400 w-full truncate ...">
          {item.user_message}
        </p>
        <div className="text-xs text-blue-600 dark:text-blue-500">
          {timeAgo(item.created_at)}
        </div>
      </div>
    </>
  );
  return item.user_uri == null ? (
    <div className="flex px-4 py-3 w-full">{content}</div>
  ) : (
    <Link
      to={item.user_uri}
      className="flex px-4 py-3 w-full">
      {content}
    </Link>
  );
}

export default NotificationItem;
