import { ProductValidationErrors } from "actions/products";
import GeneralProductInfo from "../add-product/general-info.component";
import ProductMedia from "./media.component";
import AddProductPricing from "../add-product/pricing.component";
import AddProductInventory from "../add-product/inventory.component";
import ProductVariations from "../add-product/variations.component";
import ShippingValues from "../add-product/shiping.component";
import ProductCategoryOptions from "../add-product/category.component";
import AddProductStatus from "../add-product/status.component";

function EditProductInfo({
  errors,
}: {
  errors: ProductValidationErrors | null;
}) {
  return (
    <div className="grid lg:grid-cols-12 gap-4  mt-4">
      <div className="lg:col-span-8 space-y-4">
        <GeneralProductInfo errors={errors} />
        <ProductMedia errors={errors} />
        <AddProductPricing errors={errors} />
        <AddProductInventory errors={errors} />
        <ProductVariations />
        <ShippingValues errors={errors} />
      </div>
      <div className="lg:col-span-4 space-y-4">
        <ProductCategoryOptions errors={errors} />
        <AddProductStatus />
      </div>
    </div>
  );
}

export default EditProductInfo;
