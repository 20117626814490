import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { Specification } from "./general-info.component";
import FormInput from "../../form/form-input.component";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";
import { selectProduct } from "store/product/product.selector";

type DescriptionProps = {
  specifications: Specification[] | null;
  setSpecifications: (value: Specification[]) => void;
};
function ProductSpecifications({
  specifications,
  setSpecifications,
}: DescriptionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = () => {
    onOpen();
  };
  const product = useSelector(selectProduct);

  const handleAddSpecification = () => {
    if (product.specifications) {
      setSpecifications([...product.specifications, { key: "", value: "" }]);
    } else {
      setSpecifications([{ key: "", value: "" }]);
    }
  };

  const handleRemoveSpecification = (index: number) => {
    if (product.specifications)
      setSpecifications(product.specifications.filter((_, i) => i !== index));
  };

  const handleChange = (
    index: number,
    field: keyof Specification,
    value: string
  ) => {
    const state = product.specifications || [];
    setSpecifications(
      state.map((spec, i) => (i === index ? { ...spec, [field]: value } : spec))
    );
  };

  return (
    <>
      <span
        className="label-text-alt text-secondary underline"
        role="button"
        onClick={handleOpen}>
        Add Specification
      </span>
      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        className="h-[95%]">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Product Specifications
              </ModalHeader>
              <ModalBody>
                {specifications?.map((spec, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2">
                    <FormInput
                      type="text"
                      placeholder="Specification Key"
                      value={spec.key}
                      onChange={(e) =>
                        handleChange(index, "key", e.target.value)
                      }
                    />
                    <FormInput
                      type="text"
                      value={spec.value}
                      onChange={(e) =>
                        handleChange(index, "value", e.target.value)
                      }
                      placeholder="Specification Value"
                    />
                    <button
                      className="btn btn-square btn-soft-danger border-0"
                      onClick={() => handleRemoveSpecification(index)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
                <div className="centered">
                  <Button
                    color="secondary"
                    onPress={handleAddSpecification}>
                    Add Specification
                  </Button>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProductSpecifications;
