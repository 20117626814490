import { FunctionComponent } from "react";
import { Button, Image, useDisclosure } from "@nextui-org/react";
import CircleImageBadge from "assets/svgs/circle-image.svg";
import { classNames } from "utils/helper/helper";
import Uploader from "../../uploader";
import { ImageData } from "utils/types/gallery";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct } from "store/product/product.selector";
import { updateCurrentProduct } from "store/product/product.actions";
import { selectTheme } from "store/theme/theme.selector";

interface ProductThumbnailProps {
  error?: string;
}

const ProductThumbnail: FunctionComponent<ProductThumbnailProps> = ({
  error,
}) => {
  const product = useSelector(selectProduct);
  const selected = product.thumbnail_img == null ? [] : [product.thumbnail_img];
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const theme = useSelector(selectTheme),
    dispatch = useDispatch();
  const handleChange = (imageData: ImageData[]) => {
    dispatch(
      updateCurrentProduct({
        name: "thumbnail_img",
        value: imageData.length === 0 ? null : imageData[0],
      })
    );
  };
  console.log("selected: ", selected);

  return (
    <>
      <input
        type="hidden"
        name="thumbnail"
        value={
          selected == null
            ? undefined
            : selected.length > 0
            ? selected[0].id
            : undefined
        }
      />
      <div>
        <div className="flex items-start gap-2 relative">
          <div className="font-medium text-gray-600 dark:text-gray-200">
            Thumbnail
          </div>
        </div>
        <div
          className={classNames(
            "flex flex-col items-center justify-center gap-[16px] px-[12px] py-[24px] relative cursor-pointer",
            "bg-neutralgraygray-25 dark:bg-dark-base/40 rounded-lg overflow-hidden border border-dashed",
            error != null ? "border-red-500" : "border-colourinputfield"
          )}
          onClick={() => onOpen()}>
          {selected === undefined ? (
            <>
              <CircleImageBadge />
              <p className="font-regular text-neutralgraygray-400 ">
                This image are visible in product display. Use 300x300 sizes
                image.
              </p>

              <Button
                color={
                  theme != null && theme === "dark" ? "secondary" : "primary"
                }
                variant="bordered"
                onPress={onOpen}>
                Add Image
              </Button>
            </>
          ) : (
            <Image
              className="rounded-lg h-28 w-28"
              alt="Selected Img"
              src={selected[0]?.path}
              style={{ width: "100%" }}
            />
          )}
        </div>
        {error != null && (
          <div className="label">
            <span className="label-text-alt text-red-600">{error}</span>
          </div>
        )}
      </div>
      <Uploader
        {...{
          isOpen,
          onOpenChange,
          handleChange,
          ratio: { width: 1, height: 1 },
          // dimensions: { width: 300, height: 300 },
          files: selected,
        }}
      />
    </>
  );
};

export default ProductThumbnail;
