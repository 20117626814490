import { Button } from "@nextui-org/react";
import logoIcon from "assets/logo.svg";
import logoWhite from "assets/logo-white.svg";
import { classNames, goToTop } from "utils/helper/helper";
import { Link, useLocation } from "react-router-dom";
import ScrollToTopButton from "./top";
import PoliciesDropdown from "./polices";
import Sidebar from "./sidebar";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "store/user/user.selector";
import { links } from "utils/helper/links";
import { trackAnalyticsEvent } from "components/toolkit/initial-state.component";

const Header = () => {
  const location = useLocation();
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const isActive = (path: string) => location.pathname.startsWith(path);
  return (
    <>
      <header
        className={classNames(
          "bg-[#1E3759] md:bg-white lg:bg-white",
          "shadow m-4 fixed inset-x-0 rounded-2xl z-20"
        )}>
        <div className="container mx-auto flex justify-between items-center p-2 pl-3 lg:py-4 lg:px-6">
          {/* Logo */}
          <Link
            to={"/"}
            onClick={goToTop}
            className="flex items-center px-2 lg:px-0">
            <img
              src={logoIcon}
              alt="CusorCart Logo"
              className="h-6 w-auto hidden md:block lg:block"
            />
            <img
              src={logoWhite}
              alt="CusorCart Logo"
              className="h-4 w-auto lg:hidden md:hidden"
            />
          </Link>

          {/* Navigation Items */}
          <nav className="hidden items-center space-x-10 md:flex lg:flex">
            <div className="lg:flex hidden">
              <PoliciesDropdown />
            </div>
            <Link
              to={"/faq"}
              onClick={goToTop}
              className={classNames(
                "text-gray-600 hover:text-gray-800 gap-2",
                isActive("faq")
                  ? "text-secondary"
                  : "text-primary hover:text-secondary"
              )}>
              FAQ
            </Link>
            {!isLoggedIn && (
              <Link
                to={"/auth/register"}
                className="text-gray-600 hover:text-secondary gap-2"
                onClick={() =>
                  trackAnalyticsEvent({
                    category: "Vendor",
                    action: "Create Account Clicked",
                    label: "Vendor Onboarding",
                  })
                }>
                Create Account
              </Link>
            )}
            {isLoggedIn ? (
              <Button
                as={Link}
                to={links.dashboard}
                color="secondary">
                Dashboard
              </Button>
            ) : (
              <Button
                as={Link}
                to={`/auth/login`}
                color="secondary">
                Sign in
              </Button>
            )}
          </nav>
          <Sidebar />
        </div>
      </header>

      <ScrollToTopButton />
    </>
  );
};

export default Header;
