import { Button, Card, CardBody, CardFooter } from "@nextui-org/react";
import NextUIImage from "components/image/next-ui-image";
import { SecondaryNextArrow, SecondaryPrevArrow } from "components/slider";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider, { Settings } from "react-slick";
import { selectPolicies } from "store/data/data.selector";
import { classNames, flattenItems, goToTop } from "utils/helper/helper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from "react";

const settings: Settings = {
  dots: false,
  arrows: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1.5,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplay: true,
  autoplaySpeed: 3500,
  nextArrow: <SecondaryNextArrow />,
  prevArrow: <SecondaryPrevArrow />,
};
function PoliciesSM() {
  const sliderRef = useRef<Slider | null>(null);

  const policies = useSelector(selectPolicies);
  const flatItems = policies ? flattenItems(policies) : [],
    rootPolicies = flatItems.filter((p) => !p.hasChildren);

  const next = () => {
    if (sliderRef.current) sliderRef.current.slickNext();
  };
  const previous = () => {
    if (sliderRef.current) sliderRef.current.slickPrev();
  };
  return (
    <div className="block lg:hidden pb-12">
      <Slider
        ref={sliderRef}
        {...settings}
        className="">
        {rootPolicies.map((policy, index: number) => (
          <div
            key={index}
            className="w-full h-full space-y-12 px-4 pb-4">
            <Card
              shadow="sm"
              className="min-w-full h-full">
              <CardBody className="overflow-visible p-0">
                <Link
                  to={`/policy/${policy.slug}`}
                  onClick={goToTop}>
                  <NextUIImage
                    shadow="none"
                    radius="none"
                    width="100%"
                    alt={policy.title}
                    className="w-full object-cover aspect-[370/228]"
                    src={policy.img}
                  />
                </Link>
              </CardBody>
              <CardFooter className="text-small justify-start items-start h-20">
                <Link
                  to={`/policy/${policy.slug}`}
                  className="text-primary text-sm lg:text-xl font-semibold uppercase">
                  {policy.title}
                </Link>
              </CardFooter>
            </Card>
          </div>
        ))}
      </Slider>
      <div className="w-full centered gap-4 pt-4">
        <Button
          className={classNames("w-10 h-10 bg-white text-primary")}
          variant="bordered"
          color="secondary"
          onClick={previous}>
          Prev
        </Button>
        <Button
          className={classNames("w-10 h-10 bg-white text-primary")}
          variant="bordered"
          color="secondary"
          onClick={next}>
          Next
        </Button>
      </div>
    </div>
  );
}

export default PoliciesSM;
