import React, { useEffect, useRef } from "react";

const ScrollToTopButton: React.FC = () => {
    const progressWrapRef = useRef<HTMLDivElement | null>(null);
    const progressPathRef = useRef<SVGPathElement | null>(null);

    useEffect(() => {
        const progressWrap = progressWrapRef.current;
        const progressPath = progressPathRef.current;

        if (!progressPath || !progressWrap) return;

        const pathLength = progressPath.getTotalLength();

        // Initialize the path length
        progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
        progressPath.style.strokeDashoffset = String(pathLength);

        // Update scroll progress
        const updateProgress = () => {
            const scrollPosition = window.scrollY;
            const windowHeight =
                document.documentElement.scrollHeight - window.innerHeight;
            const scrollPercent = scrollPosition / windowHeight;
            const drawLength = pathLength * scrollPercent;

            // Update the path stroke-dashoffset
            progressPath.style.strokeDashoffset = String(
                pathLength - drawLength
            );

            // Show or hide the button
            if (scrollPosition > 100) {
                progressWrap.classList.add(
                    "opacity-100",
                    "visible",
                    "translate-y-0"
                );
                progressWrap.classList.remove(
                    "opacity-0",
                    "invisible",
                    "translate-y-3"
                );
            } else {
                progressWrap.classList.add(
                    "opacity-0",
                    "invisible",
                    "translate-y-3"
                );
                progressWrap.classList.remove(
                    "opacity-100",
                    "visible",
                    "translate-y-0"
                );
            }
        };

        // Smooth scroll to top
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };

        // Add scroll event listener
        window.addEventListener("scroll", updateProgress);

        // Add click event listener for back to top
        progressWrap.addEventListener("click", scrollToTop);

        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener("scroll", updateProgress);
            progressWrap.removeEventListener("click", scrollToTop);
        };
    }, []);

    return (
        <div
            ref={progressWrapRef}
            className="progress-wrap fixed bg-white dark:bg-dark-bg text-[#54a8c7] w-[2.3rem] h-[2.3rem] cursor-pointer block shadow-[inset_0_0_0_0.1rem_rgba(128,130,134,0.25)] z-[1010] opacity-0 invisible translate-y-3 transition-all duration-[0.2s] ease-[linear,margin-right] delay-[0s] rounded-[100%] right-6 bottom-6 motion-reduce:transition-none"
        >
            <svg
                className="progress-circle svg-content"
                width="100%"
                height="100%"
                viewBox="-1 -1 102 102"
            >
                <path
                    ref={progressPathRef}
                    className="fill-none stroke-[#54a8c7] stroke-[4] box-border transition-all duration-[0.2s] ease-linear motion-reduce:transition-none"
                    d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
                />
            </svg>

            {/* Add your SVG icon inline here */}
            <div className="absolute centered w-full h-full inset-0 z-[1]">
                <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-[1em] h-[1em]"
                >
                    <path d="M18.78 15.78a.749.749 0 0 1-1.06 0L12 10.061 6.28 15.78a.749.749 0 1 1-1.06-1.06l6.25-6.25a.749.749 0 0 1 1.06 0l6.25 6.25a.749.749 0 0 1 0 1.06Z" />
                </svg>
            </div>
        </div>
    );
};

export default ScrollToTopButton;
