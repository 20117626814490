import DashboardLayout from "components/dashboard/layouts/main-layout";
import NotificationList from "components/dashboard/notifications";
import PageHeader from "components/main/page-header.component";

function NotificationPage() {
  return (
    <DashboardLayout name="support">
      <main className="main-content relative">
        <PageHeader title="Notifications" />
        <NotificationList />
      </main>
    </DashboardLayout>
  );
}

export default NotificationPage;
