import { Button } from "@nextui-org/react";
import React from "react";

export default function FormButton({
    children,
    loading,
}: {
    children: JSX.Element | string;
    loading: boolean;
}) {
    return (
        <Button
            color="secondary"
            className="absolute inset-y-0 end-0 m-0.5 lg:!px-6 flex items-center skiptranslate"
            type="submit"
            isLoading={loading}
            isDisabled={loading}
        >
            {loading ? "loading" : children}
        </Button>
    );
}
