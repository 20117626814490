import FormFileButton from "components/form/file-button.component";
import NextUIImage from "components/image/next-ui-image";
import Uploader from "components/uploader";
import { selectProduct } from "store/product/product.selector";
import { Color } from "utils/types/color";
import { ImageData } from "utils/types/gallery";
import { useDisclosure } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { LiaTimesSolid } from "react-icons/lia";
import { updateCurrentProduct } from "store/product/product.actions";

type Props = {
  color: Color;
};

function AddColorImages({ color }: Props) {
  const dispatch = useDispatch();
  const product = useSelector(selectProduct);
  const colorImages = product.color_images.find((i) => i.color_id === color.id);
  const selected = colorImages?.images || [];

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const handleChange = (imageData: ImageData[]) => {
    dispatch(
      updateCurrentProduct({
        name: "color_images",
        value: product.color_images.map((ci) =>
          ci.color_id === color.id ? { ...ci, images: imageData } : ci
        ),
      })
    );
  };

  const handleRemoveImage = (image: ImageData) => {
    const updatedImages = selected.filter((i) => i.id !== image.id);
    dispatch(
      updateCurrentProduct({
        name: "color_images",
        value: product.color_images.map((ci) =>
          ci.color_id === color.id ? { ...ci, images: updatedImages } : ci
        ),
      })
    );
  };

  return (
    <>
      <div>
        <div>
          <div className="label">
            <span className="label-text">{color.name}</span>
          </div>
          <div className="flex items-center gap-3">
            <div
              className="rounded-lg h-12 w-16"
              style={{
                backgroundColor: color.code,
              }}
            />
            <FormFileButton
              onClick={() => onOpen()}
              placeholder={
                selected.length > 0
                  ? `${selected.length} files selected`
                  : undefined
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-5 mt-3 gap-3">
          {selected.map((image) => (
            <div
              className="relative w-full z-[1]"
              key={image.id}>
              <NextUIImage
                width={"auto"}
                height={"auto"}
                src={image.path}
                className="rounded-lg w-full aspect-square z-[1]"
              />
              <button
                onClick={() => handleRemoveImage(image)}
                className="btn btn-error btn-circle btn-sm absolute -top-1 -right-1 z-[2]">
                <LiaTimesSolid
                  size={15}
                  color="white"
                />
              </button>
            </div>
          ))}
        </div>
      </div>
      <Uploader
        {...{
          isOpen,
          onOpenChange,
          ratio: { width: 1, height: 1 },
          // dimensions: { width: 500, height: 500 },
          handleChange,
          files: selected,
          multiple: true,
        }}
      />
    </>
  );
}

export default AddColorImages;
