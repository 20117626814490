import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { Product, ProductResponse } from "utils/types/products";

export type ProductValidationErrors = {
  image?: string;
  icon?: string;
  name?: string;
};

export const getProductBySlug = async (
  slug: string
): Promise<ProductResponse | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders(undefined, true);

  let product: Product | null = null;

  try {
    const req = await fetch(
        `${envProps.base_url}/api/v1/vendor/get-product/${slug}`,
        {
          headers,
        }
      ),
      res = await req.json();
    console.log(res);

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, Brand not found.");
    }
  } catch (error) {
    console.error("Error fetching Product:", error);
    // throw new Error("Error fetching brand");
  }

  return product;
};
