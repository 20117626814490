import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
} from "@nextui-org/react";
import { ChangeEvent, useState } from "react";
import { FaSearch } from "react-icons/fa";
import MenuSearchItem from "./search-item.component";
import { searchSidebarLinks, sidebarLinks } from "utils/helper/links";
import { useSelector } from "react-redux";
import { selectSearchHistory } from "store/data/data.selector";
import EmptySvg from "assets/svgs/empty.svg";
import { BiSearchAlt } from "react-icons/bi";
import { classNames } from "utils/helper/helper";

function MenuSearch() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const searchHistory = useSelector(selectSearchHistory);
  const filtered = searchSidebarLinks(sidebarLinks, searchTerm);
  const searchResults = searchTerm.length > 0 ? filtered : searchHistory;

  return (
    <>
      <input
        type="text"
        placeholder="Search in Menu"
        className={classNames(
          "input input-bordered input-md w-full max-w-xs h-11 bg-transparent",
          "group-[.sidebar-size-sm]:hidden"
        )}
        onClick={() => onOpen()}
      />
      <Button
        isIconOnly
        color="secondary"
        variant="ghost"
        aria-label="Search"
        size="sm"
        onPress={() => onOpen()}
        className="group-[.sidebar-size-sm]:flex hidden">
        <BiSearchAlt />
      </Button>
      <Modal
        isOpen={isOpen}
        hideCloseButton={true}
        size="xl"
        onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalBody className="!p-0">
                <div className="flex items-center justify-center">
                  <div className="w-full">
                    <div className="p-4 border-b border-gray-700 flex items-center">
                      <FaSearch className="text-gray-400 mr-2" />
                      <input
                        type="text"
                        placeholder="Search documentation"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full bg-transparent focus:outline-none text-gray-400 placeholder-gray-400"
                      />
                      <kbd className="space-x-0.5 rtl:space-x-reverse items-center font-sans text-center shadow-small bg-default-100 text-foreground-600 rounded-small hidden md:block border-none px-2 py-1 ml-2 font-medium text-[0.6rem]">
                        <span>ESC</span>
                      </kbd>
                    </div>
                    <div className="overflow-y-auto max-h-[60vh] hover:scrollbar-show">
                      <div className="p-4">
                        <div className="text-gray-400 mb-2">
                          {searchTerm.length === 0 ? "Recent" : "Results"}
                        </div>
                        <div className="space-y-2">
                          {searchResults != null ? (
                            searchResults.map((item, i) => (
                              <MenuSearchItem
                                item={item}
                                key={i}
                              />
                            ))
                          ) : (
                            <div className="text-center text-sm text-gray-400">
                              No Search
                            </div>
                          )}
                          {filtered.length === 0 && (
                            <div className="flex flex-col gap-3 items-center justify-center">
                              <EmptySvg className="h-10 w-auto" />
                              <span className="text-sm text-gray-400">
                                No Results found for {searchTerm}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default MenuSearch;
