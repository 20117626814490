import { Link } from "react-router-dom";
import { classNames, timeAgo } from "utils/helper/helper";
import { Activity } from "utils/types/activity";

function NotificationItem({ item }: { item: Activity }) {
  const notification = (
    <div
      className={classNames(
        "card !rounded-lg w-full shadow p-0 overflow-hidden border-0",
        item.user_uri != null
          ? "bg-secondary-200 dark:bg-dark-base"
          : "bg-white dark:bg-primary"
      )}>
      <div className="card-body p-4">
        <h2 className="card-title capitalize">{item.type}</h2>
        <p>{item.user_message}</p>
      </div>
      <div className="card-footer bg-primary px-4 py-2 text-white border-t-1 border-gray-400">
        {timeAgo(item.created_at)}
      </div>
    </div>
  );
  return item.user_uri != null ? (
    <Link to={item.user_uri}>{notification}</Link>
  ) : (
    <div>{notification}</div>
  );
}

export default NotificationItem;
