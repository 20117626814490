import EmptySvg from "assets/svgs/empty.svg";
import { classNames } from "utils/helper/helper";
import { ImageData } from "utils/types/gallery";
import { Image, Skeleton } from "@nextui-org/react";
// import UploadFilter from "./uploads-filter.component";

type UploadListProps = {
  selected: ImageData[] | null;
  handleSelect: (value: ImageData) => void;
  uploads: ImageData[] | null;
};

const UploadList = ({ uploads, handleSelect, selected }: UploadListProps) => {
  const emptyData = new Array(24).fill(0);

  const isSelected = (id: number) =>
    selected == null ? false : selected.some((item) => item.id === id);

  const loadingContent = (
    <div className="overflow-y-auto h-[60vh] hover:scrollbar-show">
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 p-3">
        {emptyData.map((_, i) => (
          <div key={i} className="card shadow w-full space-y-5 p-4 h-fit">
            <Skeleton isLoaded={false} className="rounded-lg h-24">
              <div className="h-24 rounded-lg bg-secondary"></div>
            </Skeleton>
            <Skeleton isLoaded={false} className="w-3/5 h-3 rounded-lg">
              <div className="h-3 w-full rounded-lg bg-secondary"></div>
            </Skeleton>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-3">
      {/* <UploadFilter /> */}
      {uploads == null ? (
        loadingContent
      ) : (
        <div className="overflow-y-auto h-[60vh] hover:scrollbar-show">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-2 p-3">
            {uploads.map((item, i) => (
              <div key={i} onClick={() => handleSelect(item)}>
                <div
                  className={classNames(
                    "card shadow w-full space-y-5 p-4 h-fit cursor-pointer border-gray-600 dark:border-gray-400 centered",
                    isSelected(item.id) &&
                      "!border-2 dark:!border-secondary-400"
                  )}
                >
                  <Image
                    className="rounded-lg h-28 w-full bg-gray-400"
                    alt={item.file_original_name}
                    src={item.path}
                    style={{ width: "100%" }}
                  />
                  <div className="w-full truncate">
                    {item.file_original_name}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {uploads.length === 0 && (
            <div className="flex flex-col items-center justify-center p-2 gap-4">
              <EmptySvg className="h-40" />
              <span>No Files Found</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadList;
