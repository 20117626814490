import { classNames } from "utils/helper/helper";
import { useState } from "react";
import { registerUser } from "lib/actions";
import FormButton from "components/toolkit/button";

function HomeNewsletterForm({
  show = true,
  buttonLabel = "Join Newsletter",
  placeholder = "Enter email to get notified",
}) {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      setLoading(true);

      // Ensure TypeScript knows event.target is a form
      const form = event.target as HTMLFormElement;

      // Pass FormData to the registerUser function and await response
      const res = await registerUser(new FormData(form));

      if (res.errors) {
        setErrors(res.errors);
      }
    } catch (error) {
      console.error("Error during user registration:", error);
    } finally {
      setLoading(false); // Reset loading state after processing
    }
  };

  // console.log(state);

  return (
    <form
      className="w-full max-w-[546px]"
      onSubmit={handleSubmit}>
      {show && (
        <label
          htmlFor="news-email"
          className="block mb-2 text-base font-normal text-[#6C6C72] dark:text-white">
          Get notified about our app launch
        </label>
      )}

      <div className="relative w-full">
        <input
          type="text"
          id="news-email"
          name="email"
          className={classNames(
            "bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500",
            "focus:border-blue-500 block w-full p-2.5 h-[45px] dark:bg-gray-700 dark:border-gray-600",
            "dark:placeholder-gray-400 dark:text-white outline-none dark:focus:ring-blue-500 dark:focus:border-blue-500"
          )}
          placeholder={placeholder}
        />
        <FormButton loading={loading}>
          <>{buttonLabel}</>
        </FormButton>
      </div>
      {errors.email && (
        <div className="label">
          <span className="label-text-alt text-red-600">{errors.email}</span>
        </div>
      )}
    </form>
  );
}

export default HomeNewsletterForm;
