import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import Editor from "../quill/editor";
import { classNames, stripTagsAndLimit } from "utils/helper/helper";

type DescriptionProps = {
  description: string;
  handleChange: (string: string) => void;
  errorMessage?: string | null;
};
function ProductDescription({
  description,
  handleChange,
  errorMessage,
}: DescriptionProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = () => {
    onOpen();
  };
  const value = [
    `${stripTagsAndLimit(description)}${
      stripTagsAndLimit(description).length === 160 ? ". . ." : ""
    }`,
  ];
  console.log(value);

  return (
    <>
      <textarea
        className={classNames(
          "textarea textarea-bordered h-24",
          errorMessage != null && "border-red-600"
        )}
        role="button"
        onClick={handleOpen}
        value={value}
        placeholder="Type product description here. . ."
        readOnly></textarea>
      {errorMessage != null && (
        <div className="label">
          <span className="label-text-alt text-red-600">{errorMessage}</span>
        </div>
      )}
      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior={"inside"}
        className="h-[95%]">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Description
              </ModalHeader>
              <ModalBody className="h-full">
                <Editor
                  content={description}
                  handleChange={handleChange}
                />
                {/* <QuillEditor defaultValue={description} /> */}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default ProductDescription;
