import ProductGoToTop from "../add-product/to-top.component";
import Header from "../nav/header.conponent";
import Sidebar from "../nav/sidebar.component";
import Dispatcher from "../../toolkit/dispatcher";

type Props = {
  children?: React.ReactNode;
  name?: string;
};
function DashboardLayout({ name, children }: Props) {
  const content = (
    <>
      <Header />
      {children}
    </>
  );
  return (
    <>
      <div className="flex h-screen overflow-hidden content">
        <Sidebar />
        {name && name === "products" ? (
          <ProductGoToTop className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            {content}
          </ProductGoToTop>
        ) : (
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            {content}
          </div>
        )}
      </div>

      {name != null && <Dispatcher client={name} />}
    </>
  );
}

export default DashboardLayout;
