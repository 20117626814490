import { Card, CardBody, CardHeader } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCategories } from "store/category/category.selector";
import { classNames } from "utils/helper/helper";
import CustomSelect from "../../form/form-select.component";
import { ProductValidationErrors } from "utils/helper/product-helper";
import { updateCurrentProduct } from "store/product/product.actions";
import {
  selectProduct,
  selectRelatedCategoryData,
} from "store/product/product.selector";
import { logger } from "lib/logger";

function ProductCategoryOptions({
  errors,
}: {
  errors: ProductValidationErrors | null;
}) {
  const categories = useSelector(selectAllCategories) || [];
  const relatedData = useSelector(selectRelatedCategoryData),
    product = useSelector(selectProduct),
    dispatch = useDispatch();
  logger.log("product", product);
  const selectedCategory = categories.find((i) => i.id === product.category_id);

  const brands = relatedData?.brands,
    brandOptions = brands?.map((i) => ({ label: i.name, value: i.id })),
    selectedBrand = brandOptions?.find((i) => i.value === product.brand_id);

  const handleBrandChange = (target: { value: number; label: string }) => {
    const { value } = target;
    dispatch(
      updateCurrentProduct({
        name: "brand_id",
        value,
      })
    );
  };

  logger.log(categories, selectedCategory);

  return (
    <Card
      className="py-4 lg:px-4 overflow-visible shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Category - {relatedData?.name}</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-4 space-y-2">
        <div className={classNames(errors?.brand_id != null && "has-error")}>
          <CustomSelect
            label="Product Brand"
            options={brandOptions}
            value={selectedBrand}
            onChange={(value) => value != null && handleBrandChange(value)}
          />
          {errors?.brand_id != null && (
            <div className="label">
              <span className="label-text-alt text-red-600">
                {errors?.brand_id}
              </span>
            </div>
          )}
        </div>
        {/* <div className={classNames(errors?.tags != null && "has-error")}>
          <CustomSelect
            label="Category Tags"
            value={selectTags}
            options={tagOptions}
            onChange={(value) =>
              value != null && handleTagsChange(value.map((tag) => tag))
            }
            isMulti
          />
          {errors?.tags != null && (
            <div className="label">
              <span className="label-text-alt text-red-600">
                {errors?.tags}
              </span>
            </div>
          )}
        </div> */}
      </CardBody>
    </Card>
  );
}

export default ProductCategoryOptions;
