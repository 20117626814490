import { FunctionComponent } from "react";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import ProductThumbnail from "./thumbnail.component";
import ProductPhotos from "./photos.component";
import { ProductValidationErrors } from "utils/helper/product-helper";

interface ProductMediaProps {
  errors: ProductValidationErrors | null;
}

const ProductMedia: FunctionComponent<ProductMediaProps> = ({ errors }) => {
  return (
    <Card
      className="py-4 lg:px-4 shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Media</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-2 px-4 space-y-3">
        <ProductThumbnail error={errors?.thumbnail_img} />
        <ProductPhotos error={errors?.photos} />
      </CardBody>
    </Card>
  );
};

export default ProductMedia;
