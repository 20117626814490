function CircleImageBadge({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width={44}
      height={45}
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect
        x={2}
        y="2.5"
        width={40}
        height={40}
        rx={20}
        fill="#BBD8FE"
      />
      <rect
        x={2}
        y="2.5"
        width={40}
        height={40}
        rx={20}
        stroke="#EFEFFD"
        strokeWidth={4}
      />
      <path
        d="M17.3333 20.7502C18.2998 20.7502 19.0833 19.9667 19.0833 19.0002C19.0833 18.0337 18.2998 17.2502 17.3333 17.2502C16.3668 17.2502 15.5833 18.0337 15.5833 19.0002C15.5833 19.9667 16.3668 20.7502 17.3333 20.7502Z"
        fill="#102847"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7083 11.7085C12.7753 11.7085 11.2083 13.2755 11.2083 15.2085V29.7918C11.2083 31.7248 12.7753 33.2918 14.7083 33.2918H29.2916C31.2246 33.2918 32.7916 31.7248 32.7916 29.7918V15.2085C32.7916 13.2755 31.2246 11.7085 29.2916 11.7085H14.7083ZM29.2916 14.0418H14.7083C14.0639 14.0418 13.5416 14.5642 13.5416 15.2085V24.2713L16.4568 22.4773C16.6508 22.358 16.8966 22.3624 17.0861 22.4888L20.1974 24.563L25.1418 20.7174C25.3525 20.5535 25.6474 20.5535 25.8581 20.7174L30.4583 24.2953V15.2085C30.4583 14.5642 29.9359 14.0418 29.2916 14.0418ZM13.5416 29.7918V27.0111L16.7248 25.0522L20.3025 27.4373L25.5 23.3948L30.4583 27.2513V29.7918C30.4583 30.4362 29.9359 30.9585 29.2916 30.9585H14.7083C14.0639 30.9585 13.5416 30.4362 13.5416 29.7918Z"
        fill="#102847"
      />
    </svg>
  );
}

export default CircleImageBadge;
