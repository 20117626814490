import { FunctionComponent, useEffect, useState } from "react";
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import AddVariant from "./variations/add-variant.component";
import AddColors from "./variations/colors.component";
import AddAttributes from "./variations/attributes.component";
import ColorImages from "./variations/color-images.component";
import AddVariations from "./variations";
import { useSelector } from "react-redux";
import { selectProduct } from "store/product/product.selector";

interface ProductVariationsProps {
  error?: string;
}

const ProductVariations: FunctionComponent<ProductVariationsProps> = ({
  error,
}) => {
  const [hasColor, setHasColor] = useState(false);
  const [hasAttribute, setHasAttribute] = useState(false);
  const product = useSelector(selectProduct);

  useEffect(() => {
    if (product.colors.length > 0) {
      setHasColor(true);
    }
    if (product.attributes.length > 0) {
      setHasAttribute(true);
    }
  }, [product.attributes.length, product.colors.length]);
  return (
    <Card
      className="py-4 lg:px-4 overflow-visible shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Variation</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-2 px-4 space-y-3">
        {hasAttribute && <AddAttributes setHasAttribute={setHasAttribute} />}
        {hasColor && <AddColors setHasColor={setHasColor} />}
        <ColorImages />
        <AddVariant
          {...{
            hasColor,
            setHasColor,
            hasAttribute,
            setHasAttribute,
          }}
        />
        <AddVariations />
      </CardBody>
    </Card>
  );
};

export default ProductVariations;
