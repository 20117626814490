import { classNames } from "utils/helper/helper";
import { InputHTMLAttributes, useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { Link } from "react-router-dom";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  errorMessage?: string | null;
  successMessage?: string | null;
  hasForgotPassword?: boolean;
  allowOnlyNumbers?: boolean; // Add a prop to toggle number-only input
}

const extractWidthClass = (className: string | undefined) => {
  if (!className) return null;
  const widthClass = className
    .split(" ")
    .find((cls) => cls.startsWith("w-") || cls.startsWith("col-"));
  return widthClass || null;
};

const FormInput: React.FC<InputFieldProps> = ({
  label,
  errorMessage,
  className,
  hasForgotPassword,
  successMessage,
  allowOnlyNumbers, // New prop
  ...props
}) => {
  const [hidden, setHidden] = useState(true);
  const { type, ...otherProps } = props;
  const inputType = type === "password" ? (hidden ? "password" : "text") : type;
  const widthClass = extractWidthClass(className) || "w-full";

  const togglePassword = () => setHidden((state) => !state);

  // Handle number-only input
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (allowOnlyNumbers) {
      // Allow only numbers, backspace, and navigation keys
      if (
        !/[0-9]/.test(e.key) &&
        e.key !== "Backspace" &&
        e.key !== "ArrowLeft" &&
        e.key !== "ArrowRight" &&
        e.key !== "Tab"
      ) {
        e.preventDefault();
      }
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (allowOnlyNumbers) {
      e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Strip non-numeric characters
    }
  };

  return (
    <label className={classNames("form-control", widthClass)}>
      {label != null && (
        <div className="label">
          <span className="label-text">
            {label} {props.required && <span className="text-red-600">*</span>}
          </span>
          {hasForgotPassword != null && hasForgotPassword && (
            <span className="label-text-alt">
              <Link
                className="inline-block text-sm underline text-secondary"
                to="/auth/forgot-password"
                rel="ugc">
                Forgot your password?
              </Link>
            </span>
          )}
        </div>
      )}
      <div className="relative">
        <input
          className={classNames(
            "input input-bordered text-[#09131f] placeholder:text-sm rounded-lg dark:text-gray-200",
            widthClass,
            errorMessage != null && "border-red-600",
            className
          )}
          type={inputType}
          onKeyDown={handleKeyDown} // Attach event handler
          onInput={handleInput} // Attach event handler
          {...otherProps}
        />
        {type != null && type === "password" && (
          <span
            role="button"
            onClick={togglePassword}
            className="w-4 h-4 opacity-70 absolute top-4 right-4">
            {hidden ? <BsEye /> : <BsEyeSlash />}
          </span>
        )}
      </div>
      {errorMessage != null && (
        <div className="label">
          <span className="label-text-alt text-red-600">{errorMessage}</span>
        </div>
      )}
      {successMessage != null && (
        <div className="label">
          <span className="label-text-alt text-green-600">
            {successMessage}
          </span>
        </div>
      )}
    </label>
  );
};

export default FormInput;
