"use client";

import { useDispatch, useSelector } from "react-redux";
import { FormEvent, useEffect, useState } from "react";
import { ProductValidationErrors } from "actions/products";
import {
  allCombinationsValid,
  isProductFormValidated,
  validateColorImages,
  validateProductForm,
} from "utils/helper/product-helper";
import {
  selectCurrentAttributes,
  selectProduct,
} from "store/product/product.selector";
import { alertMessage } from "../../toolkit/initial-state.component";
import { uploadEditProduct } from "store/product/product.actions";
import { selectCurrentUser } from "store/user/user.selector";
import { Product, ProductResponse } from "utils/types/products";
import EditProductPageHeader from "./page-header.component";
import { productActions } from "store/product/product.slice";
import EditProductCategoryForm from "./category";
import { classNames } from "utils/helper/helper";
import EditProductStepper from "./stepper";
import EditProductInfo from "./product-info";

function EditProductForm({
  editProduct,
  productResponse,
}: {
  editProduct: Product;
  productResponse: ProductResponse;
}) {
  const [errors, setErrors] = useState<ProductValidationErrors | null>(null),
    [step, setStep] = useState(2),
    dispatch = useDispatch(),
    product = useSelector(selectProduct),
    user = useSelector(selectCurrentUser),
    attributes = useSelector(selectCurrentAttributes);

  console.log(editProduct, productResponse, product);
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!isProductFormValidated(product)) {
      setErrors(validateProductForm(product));
      return;
    }
    if (
      product.colors.length > 0 &&
      !validateColorImages(product.color_images)
    ) {
      alertMessage("warn", "Please select a color images");
      return;
    }
    if (product.colors.length > 0 || attributes != null) {
      if (product.variations && !allCombinationsValid(product.variations)) {
        alertMessage("warn", "Please complete variations...");
        return;
      }
    }
    const data: Product =
      user != null ? { ...product, user_id: user.id } : product;
    dispatch(
      uploadEditProduct({
        action: productResponse.actions.update,
        product: data,
      })
    );
  };
  useEffect(() => {
    if (product.id == null) {
      dispatch(productActions.setProduct(editProduct));
      dispatch(productActions.setRelatedData(productResponse.relatedData));
    } else {
      if (product.id !== editProduct.id) {
        dispatch(productActions.setProduct(editProduct));
        dispatch(productActions.setRelatedData(productResponse.relatedData));
      }
    }
  }, [dispatch, editProduct, product.id, productResponse.relatedData]);
  return (
    <form onSubmit={handleSubmit}>
      <EditProductPageHeader />
      <EditProductStepper {...{ setStep, step }} />
      <div className={classNames(step > 1 && "hidden", "pt-4")}>
        <EditProductCategoryForm {...{ setStep }} />
      </div>
      <div className={classNames(step < 2 && "hidden")}>
        <EditProductInfo errors={errors} />
      </div>
    </form>
  );
}

export default EditProductForm;
