"use client";

import { classNames } from "utils/helper/helper";
import { SelectHTMLAttributes } from "react";

interface SelectFieldProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  errorMessage?: string | null;
  placeholder?: string;
  options?: {
    value: string | number;
    label: string;
  }[];
}

const extractWidthClass = (className: string | undefined) => {
  if (!className) return null;
  const widthClass = className.split(" ").find((cls) => cls.startsWith("w-"));
  return widthClass || null;
};

const FormSelect: React.FC<SelectFieldProps> = ({
  label,
  errorMessage,
  className,
  placeholder,
  options,
  ...props
}) => {
  const widthClass = extractWidthClass(className) || "w-full";

  return (
    <>
      <label className={classNames("form-control", widthClass)}>
        {label != null && (
          <div className="label">
            <span className="label-text">{label}</span>
          </div>
        )}
        <select
          className={classNames(
            "select select-bordered",
            errorMessage != null && "border-red-600"
          )}
          {...props}>
          {placeholder && (
            <option
              disabled
              selected>
              {placeholder}
            </option>
          )}

          {options?.map((option, i) => (
            <option
              value={option.value}
              key={i}>
              {option.label}
            </option>
          ))}
        </select>
        {errorMessage != null && (
          <div className="label">
            <span className="label-text-alt text-red-600">{errorMessage}</span>
          </div>
        )}
      </label>
    </>
  );
};

export default FormSelect;
