import { Card, CardBody, CardHeader } from "@nextui-org/react";
import FormInput from "../../form/form-input.component";
import ProductSpecifications from "./specifications.component";
import ProductDescription from "./description.component";
import { ChangeEvent, useState } from "react";
import { Product } from "utils/types/products";
import { updateCurrentProduct } from "store/product/product.actions";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct } from "store/product/product.selector";
import { ProductValidationErrors } from "utils/helper/product-helper";

export interface Specification {
  key: string;
  value: string;
}

function GeneralProductInfo({
  errors,
}: {
  errors: ProductValidationErrors | null;
}) {
  const product = useSelector(selectProduct),
    dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateCurrentProduct({ name: name as keyof Product, value }));
  };
  const setDescription = (value: string) => {
    dispatch(updateCurrentProduct({ name: "description", value }));
  };
  const updateSpecifications = (value: Specification[]) => {
    dispatch(updateCurrentProduct({ name: "specifications", value }));
  };
  return (
    <Card
      className="py-4 lg:px-4 shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">General Information</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-4 space-y-2">
        <FormInput
          type="text"
          name="name"
          label="Product Name"
          placeholder={"Type product name here. . ."}
          value={product.name}
          onChange={handleChange}
          errorMessage={errors?.name}
        />
        <div className="form-control">
          <div className="label">
            <span className="label-text">Description</span>
            <ProductSpecifications
              {...{
                specifications: product.specifications,
                setSpecifications: updateSpecifications,
              }}
            />
          </div>
          <ProductDescription
            {...{
              description: product.description,
              handleChange: (value: string) => setDescription(value),
              errorMessage: errors?.description,
            }}
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default GeneralProductInfo;
