import EditProductForm from "components/dashboard/edit-product";
import DashboardLayout from "components/dashboard/layouts/main-layout";
import { useLoaderData } from "react-router-dom";
import { convertProductResponseToProduct } from "utils/helper/product-helper";
import { ProductResponse } from "utils/types/products";

const EditProduct = () => {
  const productResponse = useLoaderData() as ProductResponse;

  const product = convertProductResponseToProduct(productResponse);

  return (
    <DashboardLayout name="products">
      <main className="main-content">
        <EditProductForm
          editProduct={product}
          productResponse={productResponse}
        />
      </main>
    </DashboardLayout>
  );
};

export default EditProduct;
