import { classNames } from "utils/helper/helper";

export const SectionTitle = ({ title = "", colorClassName = "" }) => (
  <h1
    className={classNames(
      "text-xl lg:text-3xl font-bold text-center capitalize",
      colorClassName ? colorClassName : "text-dark-bg"
    )}>
    {title}
  </h1>
);
export const SectionSubTitle = ({ text = "", colorClassName = "" }) => (
  <p
    className={classNames(
      "text-sm lg:text-base font-medium text-center lg:max-w-[601px] capitalize",
      colorClassName ? colorClassName : "text-[#09131f]"
    )}>
    {text}
  </p>
);
