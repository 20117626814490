import { Card, CardBody, CardHeader } from "@nextui-org/react";
import FormInput from "../../form/form-input.component";
import FormSelect from "../../form/select.component";
import { weight_units } from "utils/helper/states";
import { useDispatch, useSelector } from "react-redux";
import { selectProduct } from "store/product/product.selector";
import { ChangeEvent } from "react";
import { Product } from "utils/types/products";
import { ProductValidationErrors } from "utils/helper/product-helper";
import { updateCurrentProduct } from "store/product/product.actions";

function ShippingValues({
  errors,
}: {
  errors: ProductValidationErrors | null;
}) {
  const product = useSelector(selectProduct),
    dispatch = useDispatch();
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    dispatch(updateCurrentProduct({ name: name as keyof Product, value }));
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateCurrentProduct({ name: name as keyof Product, value }));
  };
  return (
    <Card
      className="py-4 lg:px-4 shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Shipping</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-4 space-y-2">
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
          <div className="lg:col-span-2">
            <FormInput
              type="number"
              lang="en"
              min="0"
              step="0.01"
              name="weight"
              label={`Weight`}
              placeholder={`Product weight. . .`}
              value={product.weight}
              onChange={handleChange}
              required
              errorMessage={errors?.weight}
            />
          </div>
          <div className="lg:col-span-2">
            <FormSelect
              options={weight_units}
              placeholder="Select a Weight Unit"
              label="Weight Unit"
              name="weight_unit"
              defaultValue={
                product.weight_unit == null ? undefined : product.weight_unit
              }
              onChange={handleSelectChange}
              required
              errorMessage={errors?.weight_unit}
            />
          </div>
          <FormInput
            type="number"
            lang="en"
            min="0"
            step="0.01"
            value={product.height}
            onChange={handleChange}
            name="height"
            label={`Height`}
            placeholder={`Height (cm). . .`}
            required
          />
          <FormInput
            type="number"
            lang="en"
            min="0"
            value={product.length}
            onChange={handleChange}
            step="0.01"
            name="length"
            label={`Length`}
            placeholder={`Length (cm). . .`}
            required
          />
          <FormInput
            type="number"
            lang="en"
            value={product.width}
            onChange={handleChange}
            min="0"
            step="0.01"
            name="width"
            label={`Width`}
            className="col-span-2"
            placeholder={`Width (cm). . .`}
            required
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default ShippingValues;
