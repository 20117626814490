/* eslint-disable @typescript-eslint/no-unused-vars */
import Select, { Props } from "react-select";
import type {} from "react-select/base";

// This import is necessary for module augmentation.
// It allows us to extend the 'Props' interface in the 'react-select/base' module
// and add our custom property 'label' to it.

declare module "react-select/base" {
  export interface Props<
    Option,
    IsMulti extends boolean,
    Group extends GroupBase<Option>
  > {
    label?: string;
  }
}
interface GroupBase<Option> {
  readonly options: readonly Option[];
  readonly label?: string;
}

function mergeClassNames(baseClassNames: any, customClassNames: any) {
  return {
    ...baseClassNames,
    ...Object.fromEntries(
      Object.entries(customClassNames).map(([key, value]) => [
        key,
        (...args: any[]) => {
          const base = baseClassNames[key] ? baseClassNames[key](...args) : "";
          return `${base} ${
            typeof value === "function" ? value(...args) : value
          }`;
        },
      ])
    ),
  };
}

function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const customClassNames = {
    control: () =>
      "dark:bg-primary-950 dark:border-[#a6adbb]/20 dark:text-gray-200 custom-select",
    container: () => "dark:bg-primary-950",
    singleValue: () => "dark:!text-gray-200 dark:hover:bg-secondary-400",
    menuList: () => "dark:!bg-primary-950 dark:text-gray-200 ",
  };

  return (
    <div className="w-full">
      {props.label != null && (
        <div className="label">
          <span className="label-text">
            {props.label}{" "}
            {props.required && <span className="text-red-600">*</span>}
          </span>
        </div>
      )}
      <Select
        {...props}
        closeMenuOnSelect={props.isMulti === undefined ? true : !props.isMulti}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: 8,
          }),
          option: (styles, { isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isSelected
                ? "#ffa815"
                : isFocused
                ? "rgba(255, 168, 21, 0.5)"
                : undefined,
              color: isSelected ? "#000" : isFocused ? "#000" : undefined,
              cursor: isDisabled ? "not-allowed" : "pointer",

              ":active": {
                ...styles[":active"],
                backgroundColor: isSelected ? "#ffa815" : undefined,
              },
            };
          },
          menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 8903834,
          }),
        }}
        classNames={{
          control: (state) =>
            "dark:bg-primary-950 dark:border-[#a6adbb]/20 dark:text-gray-200 h-full custom-select",
          container: (state) => "",
          singleValue: (state) =>
            "dark:!text-gray-200 dark:hover:bg-secondary-400",
          menuList: (state) => "dark:!bg-primary-950 dark:text-gray-200",
          multiValue: (state) => "dark:!bg-secondary-400",
          multiValueRemove: (state) => "dark:text-red-500",
          input: (state) => "dark:text-gray-200",
        }}
      />
    </div>
  );
}

export default CustomSelect;
