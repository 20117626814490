import { createSelector } from "reselect";
import { RootState } from "../store";
import { ExtentionType } from "components/uploader";

const selectGalleryReducer = (state: RootState) => state.gallery;

export const selectGalleryData = createSelector(
  [selectGalleryReducer],
  (gallery) => gallery.data
);

export const selectGalleryByEXT = (ext: ExtentionType) =>
  createSelector(
    [selectGalleryReducer],
    (gallery) => gallery.data?.filter((i) => i.type === ext) || null
  );
