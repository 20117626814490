import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { classNames, goToTop } from "utils/helper/helper";
import { PoliciesAccordion } from "./polices";
import BurgerMenu from "./burger";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "store/user/user.selector";
import { links } from "utils/helper/links";
import { trackAnalyticsEvent } from "components/toolkit/initial-state.component";

function Sidebar() {
  const [open, setOpen] = useState(false);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const handleClick = () => {
    setOpen(false);
    goToTop();
  };
  return (
    <div className="md:hidden lg:hidden">
      {" "}
      <Dropdown
        onOpenChange={(state) => setOpen(state)}
        isOpen={open}
        className="w-80"
        classNames={{
          base: "w-80 mt-2",
          content: "w-full",
        }}
        closeOnSelect={false}>
        <DropdownTrigger>
          <div
            role="button"
            className={classNames("p-2")}>
            <BurgerMenu isOpen={open} />
          </div>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Dynamic Actions"
          className="w-full overflow-hidden hover:!bg-transparent">
          <DropdownItem
            color={"default"}
            className="hover:!bg-transparent">
            <PoliciesAccordion handleClick={handleClick} />
          </DropdownItem>
          <DropdownItem color={"default"}>
            <Link
              to={"/faq"}
              onClick={handleClick}
              className={classNames(
                "hover:text-gray-800 gap-2 text-primary block w-full"
              )}>
              FAQ
            </Link>
          </DropdownItem>
          <DropdownItem color={"default"}>
            {isLoggedIn ? (
              <></>
            ) : (
              <Link
                to={"/auth/register"}
                onClick={() => {
                  trackAnalyticsEvent({
                    category: "Vendor",
                    action: "Create Account Clicked",
                    label: "Vendor Onboarding",
                  });
                  setOpen(false);
                }}
                className="text-gray-600 hover:text-gray-800 gap-2 block w-full">
                Create Account
              </Link>
            )}
          </DropdownItem>

          <DropdownItem
            color={"default"}
            className="hover:bg-transparent mt-2">
            {isLoggedIn ? (
              <Button
                as={Link}
                to={links.dashboard}
                onClick={() => setOpen(false)}
                className="w-full"
                color="secondary">
                Dashboard
              </Button>
            ) : (
              <Button
                as={Link}
                to={`/auth/login`}
                onClick={() => setOpen(false)}
                className="w-full"
                color="secondary">
                Sign in
              </Button>
            )}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default Sidebar;
