import FAQDetail from "components/faq";

function FAQPage() {
  return (
    <main className="w-full overflow-x-hidden bg-white">
      <FAQDetail />
    </main>
  );
}

export default FAQPage;
