import { classNames } from "utils/helper/helper";
import { ObjectHTMLAttributes } from "react";

interface FieldProps extends ObjectHTMLAttributes<HTMLElement> {
  label?: string;
  errorMessage?: string | null;
  placeholder?: string;
}
const FormFileButton: React.FC<FieldProps> = ({
  label,
  errorMessage,
  placeholder,
  ...props
}) => {
  return (
    <div className={classNames("w-full")}>
      {label != null && (
        <div className="label">
          <span className="label-text">{label}</span>
        </div>
      )}
      <div className="relative">
        <div
          className="flex items-center input-bordered border w-full h-12 rounded-md overflow-hidden"
          role="button"
          {...props}>
          <div className="bg-gray-200 dark:bg-base-color-2 h-full px-4 centered">
            Choose Files...
          </div>
          {placeholder && <span className="pl-2">{placeholder}</span>}
        </div>
      </div>
      {errorMessage != null && (
        <div className="label">
          <span className="label-text-alt text-red-600">{errorMessage}</span>
        </div>
      )}
    </div>
  );
};

export default FormFileButton;
