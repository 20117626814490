import { SvgProps } from "utils/types/app";

const FacebookSvg: React.FC<SvgProps> = (props) => {
  return (
    <svg
      width={45}
      height={45}
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1721_5588)">
        <path
          d="M26.25 25.313h4.688l1.875-7.5H26.25v-3.75c0-1.932 0-3.75 3.75-3.75h2.813v-6.3c-.612-.081-2.92-.263-5.357-.263-5.091 0-8.706 3.107-8.706 8.813v5.25h-5.625v7.5h5.625V41.25h7.5V25.312z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1721_5588">
          <path
            fill="#fff"
            d="M0 0H45V45H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FacebookSvg;
