import { takeLatest, all, call, put } from "typed-redux-saga";

import { getEnvProps } from "utils/helper/server-helper";
import { galleryActions } from "./gallery.slice";
import { GALLERY_ACTION_TYPES } from "./gallery.types";
import { FetchData } from "./gallery.actions";
import { apiRequest } from "lib/api/apiClient";

export function* fetchData() {
  try {
    const link = "/api/v1/user/gallery";
    const data = yield* call(apiRequest, link);
    if (!data) return;
    yield* put(galleryActions.setGallery(data));
  } catch (error) {
    console.log(error as Error);
  }
}
export function* onFetchData() {
  yield* takeLatest(GALLERY_ACTION_TYPES.FETCH_DATA, fetchData);
}

export function* gallerySagas() {
  yield* all([call(onFetchData)]);
}
