import { SvgProps } from "utils/types/app";

const InstagramSvg: React.FC<SvgProps> = (props) => {
  return (
    <svg
      width={46}
      height={45}
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1817_787)">
        <path
          d="M22.75 3.75c5.094 0 5.73.019 7.729.112 1.997.094 3.356.407 4.552.872a9.142 9.142 0 013.323 2.162 9.203 9.203 0 012.162 3.323c.463 1.194.778 2.555.872 4.552.088 1.999.112 2.635.112 7.729s-.019 5.73-.112 7.729c-.094 1.997-.41 3.356-.872 4.552a9.155 9.155 0 01-2.162 3.323 9.215 9.215 0 01-3.323 2.162c-1.194.463-2.555.778-4.552.872-1.999.088-2.635.112-7.729.112s-5.73-.019-7.729-.112c-1.997-.094-3.356-.41-4.552-.872a9.17 9.17 0 01-3.323-2.162 9.195 9.195 0 01-2.162-3.323c-.465-1.194-.778-2.555-.872-4.552C4.024 28.23 4 27.594 4 22.5s.019-5.73.112-7.729c.094-1.998.407-3.356.872-4.552a9.15 9.15 0 012.162-3.323 9.182 9.182 0 013.323-2.162c1.196-.465 2.554-.778 4.552-.872 1.999-.088 2.635-.112 7.729-.112zm0 9.375a9.375 9.375 0 100 18.75 9.375 9.375 0 000-18.75zm12.188-.469a2.343 2.343 0 10-4.687 0 2.343 2.343 0 004.686 0zM22.75 16.875a5.625 5.625 0 110 11.25 5.625 5.625 0 010-11.25z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1817_787">
          <path
            fill="#fff"
            transform="translate(.25)"
            d="M0 0H45V45H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InstagramSvg;
