import { useState, useEffect } from "react";

export const decodeEmail = (encoded: string) => {
  let email = "";
  const key = parseInt(encoded.substring(0, 2), 16);
  for (let i = 2; i < encoded.length; i += 2) {
    const charCode = parseInt(encoded.substring(i, i + 2), 16) ^ key;
    email += String.fromCharCode(charCode);
  }
  return email;
};
export const encodeEmail = (email: string) => {
  let encoded = [];
  const key = Math.floor(Math.random() * 256);
  encoded.push(key.toString(16).padStart(2, "0"));

  for (let i = 0; i < email.length; i++) {
    const charCode = email.charCodeAt(i) ^ key;
    encoded.push(charCode.toString(16).padStart(2, "0"));
  }

  return encoded.join("");
};

const useDecodedEmail = (cfEmail: string, load: boolean): string => {
  const [email, setEmail] = useState<string>("");

  useEffect(() => {
    if (cfEmail && load) {
      const decodedEmail = decodeEmail(cfEmail);
      setEmail(decodedEmail);
    }
  }, [cfEmail, load]);

  return email;
};

export default useDecodedEmail;
