import { all, call } from "typed-redux-saga";
import { dataSagas } from "./data/data.saga";
import { userSagas } from "./user/user.saga";
import { categorySagas } from "./category/category.saga";
import { brandSagas } from "./brand/brand.saga";
import { attributeSagas } from "./attribute/attribute.saga";
import { colorSagas } from "./color/color.saga";
import { productSagas } from "./product/product.saga";
import { blogSagas } from "./blog/blog.saga";
import { supportSagas } from "./tickets/ticket.saga";
import { activitySagas } from "./activity/activity.saga";
import { gallerySagas } from "./gallery/gallery.saga";

export function* rootSaga() {
  yield* all([
    call(dataSagas),
    call(userSagas),
    call(categorySagas),
    call(brandSagas),
    call(attributeSagas),
    call(colorSagas),
    call(productSagas),
    call(blogSagas),
    call(supportSagas),
    call(activitySagas),
    call(gallerySagas),
  ]);
}
