import FacebookSvg from "assets/svgs/social/facebook.svg";
import InstagramSvg from "assets/svgs/social/insta.svg";
import TwitterSvg from "assets/svgs/social/x.svg";
import YoutubeSvg from "assets/svgs/social/youtube.svg";
import { SectionSubTitle, SectionTitle } from "components/text";
import { classNames } from "utils/helper/helper";

const socials = {
  facebook: "https://facebook.com/cusorcart/",
  twitter: "https://twitter.com/cusorcart/",
  instagram: "https://instagram.com/cusorcart/",
  youtube: "https://youtube.com/@cusorcartwebservices669?si=_7qJq9L-poo6w3GF",
};

export const Socials = ({
  className = "text-[#102847] h-7 w-auto lg:h-10",
  toLeft = false,
}) => (
  <div
    className={classNames(
      "divide-x-1 divide-[#6C6C72] mt-4 ",
      toLeft ? "flex w-fit" : "w-full grid grid-cols-4"
    )}>
    <a
      href={socials.facebook}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(toLeft ? "pr-7" : "centered")}
      aria-label="Visit Cusorcart on Facebook">
      <FacebookSvg className={classNames(className, "hover:text-secondary")} />
    </a>
    <a
      href={socials.instagram}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(toLeft ? "px-7" : "centered")}
      aria-label="Visit Cusorcart on Instagram">
      <InstagramSvg className={classNames(className, "hover:text-secondary")} />
    </a>
    <a
      href={socials.twitter}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(toLeft ? "px-7" : "centered")}
      aria-label="Visit Cusorcart on Twitter">
      <TwitterSvg className={classNames(className, "hover:text-secondary")} />
    </a>
    <a
      href={socials.youtube}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames(toLeft ? "px-7" : "centered")}
      aria-label="Visit Cusorcart on Youtube">
      <YoutubeSvg className={classNames(className, "hover:text-secondary")} />
    </a>
  </div>
);

function HomeSocials() {
  return (
    <section className="relative pt-20 px-6 xl:px-24 space-y-12">
      <div className="flex flex-col justify-center gap-4">
        <SectionTitle title={"Follow our Socials"} />
        <div className="flex justify-center items-center">
          <SectionSubTitle
            text={`Keep tabs with the cusorcart teams growth and approach toward improving your customer experience journey.`}
          />
        </div>
      </div>
      <Socials />
    </section>
  );
}

export default HomeSocials;
