import {
  ColorImage,
  Combination,
  Product,
  ProductResponse,
} from "../types/products";

export type ProductValidationErrors = {
  name?: string;
  description?: string;
  category_id?: string;
  brand_id?: string;
  thumbnail_img?: string;
  photos?: string;
  price?: string;
  currency_id?: string;
  current_stock?: string;
  weight?: string;
  weight_unit?: string;
};

export const isProductFormValidated = (product: Product) => {
  const errors = {
    name: product.name.length === 0,
    description: product.description.length < 50,
    category_id: product.category_id === 0,
    brand_id: product.category_id === 0,
    thumbnail_img: product.thumbnail_img === null,
    photos: product.photos.length === 0,
    price: product.price === 0,
    currency_id: product.currency_id === 0,
    current_stock: product.current_stock === 0,
    weight: product.weight === 0,
    weight_unit: product.weight_unit.length === 0,
  };
  return Object.values(errors).every((error) => !error);
};

export const validateProductForm = (
  product: Product
): ProductValidationErrors => {
  const errors: ProductValidationErrors = {};

  if (product.name.length === 0) {
    errors.name = "Please name is required";
  }
  if (product.description.length < 50) {
    errors.description =
      "Please description is required and must be at least 50 characters";
  }
  if (product.category_id === 0) {
    errors.category_id = "Please category is required";
  }
  if (product.brand_id === 0) {
    errors.brand_id = "Please brand is required";
  }
  if (product.thumbnail_img === null) {
    errors.thumbnail_img = "Please thumbnail is required";
  }
  if (product.photos.length === 0) {
    errors.photos = "Please photos are required";
  }
  if (product.price === 0) {
    errors.price = "Please price is required";
  }
  if (product.currency_id === 0) {
    errors.currency_id = "Please currency is required";
  }
  if (product.current_stock === 0) {
    errors.current_stock = "Please quantity is required";
  }
  if (product.weight === 0) {
    errors.weight = "Please weight is required";
  }
  if (product.weight_unit.length === 0) {
    errors.weight_unit = "Please weight unit is required";
  }
  return errors;
};

export function allCombinationsValid(combinations: Combination[]): boolean {
  return combinations.every(
    (combination) => combination.stock > 0 && combination.price > 0
  );
}
export const validateColorImages = (colorImages: ColorImage[]): boolean => {
  if (!Array.isArray(colorImages)) {
    throw new Error("Invalid input: colorImages should be an array.");
  }

  return colorImages.every(
    (colorImage) =>
      Array.isArray(colorImage.images) && colorImage.images.length > 0
  );
};

export const convertProductResponseToProduct = (
  productResponse: ProductResponse
): Product => {
  return {
    id: productResponse.id,
    name: productResponse.name,
    slug: productResponse.slug,
    added_by: productResponse.added_by as
      | "customer"
      | "admin"
      | "staff"
      | "vendor",
    user_id: productResponse.user_id,
    category_id: productResponse.category_id,
    category_tags: [], // Assuming category_tags are not in Root
    brand_id: productResponse.brand_id,
    thumbnail_img: productResponse.thumbnail_img,
    photos: productResponse.photos,
    color_images: productResponse.color_images,
    description: productResponse.description,
    specifications:
      productResponse.specifications != null &&
      productResponse.specifications.length
        ? productResponse.specifications
        : null,
    currency_id: productResponse.currency_id,
    price: productResponse.price,
    weight: productResponse.weight,
    weight_unit: productResponse.weight_unit,
    width: productResponse.width,
    length: productResponse.length,
    height: productResponse.height,
    tags: productResponse.tags,
    current_stock: productResponse.current_stock,
    min_qty: productResponse.min_qty,
    low_stock_quantity: productResponse.low_stock_quantity
      ? Number(productResponse.low_stock_quantity)
      : null,
    discount: productResponse.discount
      ? Number(productResponse.discount)
      : null,
    discount_type: productResponse.discount_type as "amount" | "percent" | null,
    discount_start_date: productResponse.discount_start_date
      ? new Date(productResponse.discount_start_date)
      : null,
    discount_end_date: productResponse.discount_end_date
      ? new Date(productResponse.discount_end_date)
      : null,
    meta_title: productResponse.meta_title || null,
    meta_description: productResponse.meta_description || null,
    meta_img: productResponse.meta_img || null,
    barcode: productResponse.barcode || null,
    refundable: productResponse.refundable,
    todays_deal: productResponse.todays_deal,
    published: productResponse.published ? 1 : 0,
    approved: productResponse.approved,
    cash_on_delivery: productResponse.cash_on_delivery,
    featured: productResponse.featured,
    seller_featured: productResponse.seller_featured,
    colors: productResponse.variants.map((variant) => variant.color_id),
    attributes: productResponse.variants.flatMap((variant) =>
      variant.attributes.map((attr) => attr.attribute_id)
    ),
    variations: productResponse.variants.map((variant) => ({
      id: variant.id,
      color_id: variant.color_id,
      attribute_combination: {
        combination_name: variant.attributes
          .map((attr) => attr.value)
          .join(", "),
        attributes: variant.attributes.map((attr) => ({
          attribute_id: attr.attribute_id,
          attribute_value_id: attr.id,
        })),
      },
      combination_name: variant.attributes.map((attr) => attr.value).join(", "),
      stock: variant.stock,
      price: variant.price,
    })),
  };
};
