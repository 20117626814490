import React, { useState } from "react";
import useDecodedEmail from "utils/hooks/useDecodedEmail";

const EmailButton: React.FC<{ cfEmail: string }> = ({ cfEmail }) => {
  const [showEmail, setShowEmail] = useState(false);
  const email = useDecodedEmail(cfEmail, showEmail);

  const handleMouseEnter = () => {
    setShowEmail(true);
  };

  return (
    <a
      href={showEmail ? `mailto:${email}` : "#!"}
      className="centered flex-col md:flex-row lg:flex-row gap-2 text-primary hover:text-secondary"
      onMouseEnter={handleMouseEnter} // Load email when user hovers over the button
    >
      <svg
        width={100}
        height={100}
        className="w-14 lg:w-20"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          cx={50}
          cy={50}
          r={50}
          fill="currentColor"
        />
        <g clipPath="url(#clip0_1721_5715)">
          <path
            d="M35 35H65C65.4421 35 65.866 35.1756 66.1786 35.4882C66.4911 35.8007 66.6667 36.2246 66.6667 36.6667V63.3333C66.6667 63.7754 66.4911 64.1993 66.1786 64.5118C65.866 64.8244 65.4421 65 65 65H35C34.558 65 34.1341 64.8244 33.8215 64.5118C33.509 64.1993 33.3334 63.7754 33.3334 63.3333V36.6667C33.3334 36.2246 33.509 35.8007 33.8215 35.4882C34.1341 35.1756 34.558 35 35 35ZM50.1 49.4717L39.4134 40.3967L37.255 42.9367L50.1217 53.8617L62.7567 42.9283L60.5767 40.4067L50.1017 49.4717H50.1Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1721_5715">
            <rect
              width={40}
              height={40}
              fill="white"
              transform="translate(30 30)"
            />
          </clipPath>
        </defs>
      </svg>
      <span>{showEmail ? email : "[email protected]"}</span>
    </a>
  );
};

export default EmailButton;
