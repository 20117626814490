import { createSlice } from "@reduxjs/toolkit";
import { Activity } from "utils/types/activity";

// Define a function to play the notification sound

export type ActivityState = {
  readonly all: Activity[] | null;
  searchValue: "";
  loading: boolean;
};

const INITIAL_STATE: ActivityState = {
  all: null,
  searchValue: "",
  loading: false,
};

const activitySlice = createSlice({
  name: "activity",
  initialState: INITIAL_STATE,
  reducers: {
    setAllActivity(state, action) {
      state.all = action.payload;
    },
    setSearchValue(state, action) {
      state.searchValue = action.payload;
    },
    setIsLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const activityActions = activitySlice.actions;
export default activitySlice;
