import { FunctionComponent } from "react";
import { Button, Image, useDisclosure } from "@nextui-org/react";
import CircleImageBadge from "assets/svgs/circle-image.svg";
import { classNames, arrayToString } from "utils/helper/helper";
import Uploader from "../../uploader";
import { ImageData } from "utils/types/gallery";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentProduct } from "store/product/product.actions";
import { selectTheme } from "store/theme/theme.selector";
import { selectProduct } from "store/product/product.selector";

interface ProductPhotosProps {
  error?: string;
}

const ProductPhotos: FunctionComponent<ProductPhotosProps> = ({ error }) => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const product = useSelector(selectProduct),
    selected = product.photos;
  const theme = useSelector(selectTheme),
    dispatch = useDispatch();
  const handleChange = (imageData: ImageData[]) => {
    dispatch(
      updateCurrentProduct({
        name: "photos",
        value: imageData,
      })
    );
  };
  return (
    <>
      <input
        type="hidden"
        name="photos"
        value={selected == null ? undefined : arrayToString(selected)}
      />
      <div>
        <div className="flex items-start gap-2 relative">
          <div className="font-medium text-gray-600 dark:text-gray-200">
            Photos
          </div>
        </div>
        <div
          className={classNames(
            "flex flex-col items-center justify-center gap-[16px] px-[12px] py-[24px] relative cursor-pointer",
            "bg-neutralgraygray-25 dark:bg-dark-base/40 rounded-lg overflow-hidden border border-dashed",
            error != null ? "border-red-500" : "border-colourinputfield"
          )}
          onClick={() => onOpen()}>
          {selected === undefined ? (
            <>
              <CircleImageBadge />
              <p className="font-regular text-neutralgraygray-400 ">
                This image are visible in product display. Use 500x500 sizes
                images.
              </p>

              <Button
                color={
                  theme != null && theme === "dark" ? "secondary" : "primary"
                }
                variant="bordered"
                onPress={onOpen}>
                Add Image
              </Button>
            </>
          ) : (
            <div className="flex gap-4 items-center">
              {selected.map((item) => (
                <Image
                  className="rounded-lg h-28 w-28"
                  alt="Selected Img"
                  src={item.path}
                  style={{ width: "100%" }}
                />
              ))}
            </div>
          )}
        </div>
        {error != null && (
          <div className="label">
            <span className="label-text-alt text-red-600">{error}</span>
          </div>
        )}
      </div>
      <Uploader
        {...{
          isOpen,
          onOpenChange,
          ratio: { width: 1, height: 1 },
          // dimensions: { width: 500, height: 500 },
          handleChange,
          files: selected,
          multiple: true,
        }}
      />
    </>
  );
};

export default ProductPhotos;
