import { SideBarLink, SidebarChildLink } from "../types/links";

type LinksObject = {
  [key: string]: string;
};

export const sidebarLinks: SideBarLink[] = [
    {
      title: "Dashboard",
      uri: "/dashboard",
      icon: "dashboard",
    },
    {
      title: "Product",
      uri: null,
      icon: "product",
      for: "/dashboard/product",
      children: [
        {
          title: "Product List",
          uri: "/dashboard/product/products",
        },
        {
          title: "Product Reviews",
          uri: "/dashboard/product/products-reviews",
        },
      ],
    },
    {
      title: "Reports",
      uri: null,
      icon: "reports",
      for: "/dashboard/reports",
      children: [
        {
          title: "Products",
          uri: "/dashboard/reports/products",
        },
        {
          title: "Product Wishlist",
          uri: "/dashboard/reports/product-wishlist",
        },
      ],
    },
    {
      title: "Support",
      uri: null,
      icon: "support",
      for: "/dashboard/support",
      children: [
        {
          title: "Tickets",
          uri: "/dashboard/support/tickets",
        },
      ],
    },
  ],
  generateLinksObject = (links: SideBarLink[]): LinksObject => {
    const result: LinksObject = {};

    const addLinks = (
      links: (SideBarLink | SidebarChildLink)[],
      parentKey: string = ""
    ): void => {
      links.forEach((link) => {
        const key = parentKey
          ? `${parentKey}.${link.title.replace(/\s+/g, "").toLowerCase()}`
          : link.title.replace(/\s+/g, "").toLowerCase();
        if (link.uri) {
          result[key] = link.uri;
        }
        if ("children" in link && link.children) {
          addLinks(link.children, key);
        }
      });
    };

    addLinks(links);
    return result;
  },
  compileLinks = (
    links: (SideBarLink | SidebarChildLink)[],
    parentTitle: string = ""
  ): { title: string; uri: string }[] => {
    let compiledLinks: { title: string; uri: string }[] = [];

    for (const link of links) {
      const fullTitle = parentTitle
        ? `${parentTitle} > ${link.title}`
        : link.title;

      if (link.uri) {
        compiledLinks.push({
          title: fullTitle,
          uri: link.uri,
        });
      }

      if ("children" in link && link.children) {
        compiledLinks = compiledLinks.concat(
          compileLinks(link.children, fullTitle)
        );
      }
    }

    return compiledLinks;
  },
  searchSidebarLinks = (
    links: SideBarLink[],
    searchString: string
  ): { title: string; uri: string }[] => {
    const compiledLinks = compileLinks(links);

    return compiledLinks.filter((link) =>
      link.title.toLowerCase().includes(searchString.toLowerCase())
    );
  },
  links = {
    dashboard: "/dashboard",
    "product.productlist": "/dashboard/product/products",
    "product.categories": "/dashboard/product/categories",
    "product.brands": "/dashboard/product/brands",
    orders: "/dashboard/orders",
    blog: "/dashboard/blog/list",
    "vendors.payout": "/dashboard/vendors/payout",
    "vendors.commission": "/dashboard/vendors/commission",
    "reports.products": "/dashboard/reports/products",
    "reports.productwishlist": "/dashboard/reports/product-wishlist",
    analytics: "/dashboard/analysis",
    support: "/dashboard/support",
    generalsettings: "/dashboard/settings",
    "generalsettings.currency": "/dashboard/settings/currency",
    "generalsettings.shipping": "/dashboard/settings/shipping",
  },
  mainURL = "https://cusorcart.com",
  authLinks: {
    login: any;
    register: string;
    password_reset: string;
    forgot_password: string;
    verify_reset: string;
    verify: string;
  } = {
    login: "/auth/login",
    register: "/auth/register",
    forgot_password: "/auth/forgot-password",
    password_reset: "/auth/password/reset",
    verify_reset: "/auth/verify/reset",
    verify: "/auth/verify",
  };
export const allLinksToArray = Object.values(links);
