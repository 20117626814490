import {
  Action,
  ActionWithPayload,
  withMatcher,
  createAction,
} from "utils/reducer/reducer.utils";
import { GALLERY_ACTION_TYPES } from "./gallery.types";
import { PaginationResponse, ImageData } from "utils/types/gallery";
import { AccessToken } from "utils/types/user";

export type FetchData = Action<GALLERY_ACTION_TYPES.FETCH_DATA>;

export type SetData = ActionWithPayload<
  GALLERY_ACTION_TYPES.SET_DATA,
  PaginationResponse<ImageData>
>;

export const fetchGalleryData = withMatcher(
  (): FetchData => createAction(GALLERY_ACTION_TYPES.FETCH_DATA)
);

export const setData = withMatcher(
  (data: PaginationResponse<ImageData>): SetData =>
    createAction(GALLERY_ACTION_TYPES.SET_DATA, data)
);
