import { selectColors } from "store/color/color.selector";
import { useSelector } from "react-redux";
import AddColorImages from "./color-image-upload.component";
import { selectProduct } from "store/product/product.selector";

function ColorImages() {
  const product = useSelector(selectProduct),
    selected = product.colors;
  const colors = useSelector(selectColors),
    selectedColors =
      colors == null ? [] : colors.filter((col) => selected.includes(col.id));
  return (
    <>
      {selectedColors.map((color) => (
        <AddColorImages
          key={color.id}
          color={color}
        />
      ))}
    </>
  );
}

export default ColorImages;
