import { takeLatest, all, call, put } from "typed-redux-saga";

import { ATTRIBUTE_ACTION_TYPES } from "./attribute.types";
import {
  AddAttribute,
  AddAttributeValue,
  DeleteAttribute,
  DeleteAttributeValue,
  UpdateAttribute,
  UpdateAttributeValue,
} from "./attribute.actions";
import { attributeActions } from "./attribute.slice";
import { apiRequest } from "lib/api/apiClient";
import { alertMessage } from "components/toolkit/initial-state.component";
import { logger } from "lib/logger";

export function* addAttributeFunc({ payload: formValues }: AddAttribute) {
  const { callback, ...form_data } = formValues;
  yield* put(attributeActions.setLoading(true));
  try {
    const link = "/api/v1/vendor/attribute";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: form_data,
    });
    if (!data) return;
    yield* put(attributeActions.setAllAttribute(data));
    alertMessage("success", "Attribute added successfully");
    callback();
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add attribute");
  } finally {
    yield* put(attributeActions.setLoading(false));
  }
}

export function* addAttributeValueFunc({
  payload: formValues,
}: AddAttributeValue) {
  const { callback, ...form_data } = formValues;
  yield* put(attributeActions.setLoading(true));
  try {
    const link = "/api/v1/vendor/attribute-values";
    const data = yield* call(apiRequest, link, {
      method: "post",
      body: form_data,
    });
    if (!data) return;
    yield* put(attributeActions.setAllAttribute(data));
    alertMessage("success", "Attribute Value added successfully");
    callback();
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add attribute");
  } finally {
    yield* put(attributeActions.setLoading(false));
  }
}
export function* updateAttributeValueFunc({
  payload: formValues,
}: UpdateAttributeValue) {
  const { uri, ...form_data } = formValues;
  yield* put(attributeActions.setLoading(true));
  try {
    const data = yield* call(apiRequest, uri, {
      method: "put",
      body: form_data,
    });
    if (!data) return;
    yield* put(attributeActions.setAllAttribute(data));
    alertMessage("success", "Attribute value updated successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to updated attribute");
  } finally {
    yield* put(attributeActions.setLoading(false));
  }
}

export function* updateAttributeFunc({ payload: formValues }: UpdateAttribute) {
  const { uri, ...form_data } = formValues;
  yield* put(attributeActions.setLoading(true));
  try {
    const data = yield* call(
      apiRequest,
      uri,
      {
        method: "put",
        body: form_data,
      },
      true
    );
    if (!data) return;
    yield* put(attributeActions.setAllAttribute(data));
    alertMessage("success", "Attribute updated successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to updated attribute");
  } finally {
    yield* put(attributeActions.setLoading(false));
  }
}
export function* deleteAttributeValueFunc({
  payload: formValues,
}: DeleteAttributeValue) {
  yield* put(attributeActions.setLoading(true));
  try {
    const link = "/api/v1/vendor/attribute-values/" + formValues.id;
    const data = yield* call(apiRequest, link, {
      method: "delete",
    });
    if (!data) return;
    yield* put(attributeActions.setAllAttribute(data));
    alertMessage("success", "Attribute Value added successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add attribute");
  } finally {
    yield* put(attributeActions.setLoading(false));
  }
}
export function* deleteAttributeFunc({ payload: formValues }: DeleteAttribute) {
  yield* put(attributeActions.setLoading(true));
  try {
    const link = formValues.uri;
    const data = yield* call(
      apiRequest,
      link,
      {
        method: "delete",
      },
      true
    );
    if (!data) return;
    yield* put(attributeActions.setAllAttribute(data));
    alertMessage("success", "Attribute Value added successfully");
  } catch (error) {
    logger.error(error as Error);
    alertMessage("error", "Failed to add attribute");
  } finally {
    yield* put(attributeActions.setLoading(false));
  }
}

export function* fetchAttributeData() {
  try {
    const data = yield* call(apiRequest, "/api/v1/vendor/attribute");
    if (!data) return;
    yield* put(attributeActions.setAllAttribute(data));
  } catch (error) {
    console.log(error as Error);
  }
}

export function* onAddAttribute() {
  yield* takeLatest(ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE, addAttributeFunc);
}

export function* onAddAttributeValue() {
  yield* takeLatest(
    ATTRIBUTE_ACTION_TYPES.ADD_ATTRIBUTE_VALUE,
    addAttributeValueFunc
  );
}
export function* onFetchAttributes() {
  yield* takeLatest(ATTRIBUTE_ACTION_TYPES.FETCH_ALL_DATA, fetchAttributeData);
}
export function* onDeleteAttributeValue() {
  yield* takeLatest(
    ATTRIBUTE_ACTION_TYPES.DELETE_ATTRIBUTE_VALUE,
    deleteAttributeValueFunc
  );
}
export function* onDeleteAttribute() {
  yield* takeLatest(
    ATTRIBUTE_ACTION_TYPES.DELETE_ATTRIBUTE,
    deleteAttributeFunc
  );
}
export function* onUpdateAttribute() {
  yield* takeLatest(
    ATTRIBUTE_ACTION_TYPES.UPDATE_ATTRIBUTE,
    updateAttributeFunc
  );
}
export function* onUpdateAttributeValue() {
  yield* takeLatest(
    ATTRIBUTE_ACTION_TYPES.UPDATE_ATTRIBUTE_VALUE,
    updateAttributeValueFunc
  );
}

export function* attributeSagas() {
  yield* all([
    call(onAddAttribute),
    call(onAddAttributeValue),
    call(onFetchAttributes),
    call(onDeleteAttribute),
    call(onDeleteAttributeValue),
    call(onUpdateAttribute),
    call(onUpdateAttributeValue),
  ]);
}
