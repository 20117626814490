import SidebarItem from "./sidebar-item.component";
import { CiMenuFries } from "react-icons/ci";
import { Link } from "react-router-dom";
import { sidebarLinks } from "utils/helper/links";
import LogoSvg from "assets/svgs/logo.svg";
import MenuSearch from "./menu-search.component";

function SideDrawer() {
  return (
    <div className="drawer lg:hidden z-[999]">
      <input
        id="my-drawer"
        type="checkbox"
        className="drawer-toggle"
      />
      <div className="drawer-content">
        {/* Page content here */}
        <label
          htmlFor="my-drawer"
          className="btn btn-ghost drawer-button">
          <CiMenuFries className="dark:text-white w-5 h-5 " />
        </label>
      </div>
      <div className="drawer-side z-[99999999]">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"></label>
        <div className="flex flex-col w-80 min-h-full bg-white dark:bg-primary-950 text-base-content">
          {/* Sidebar content here */}
          <div className="flex items-center px-5 py-6 text-center h-header">
            <Link
              to={"/"}
              className="flex items-center gap-2">
              <LogoSvg className="h-6 w-auto mx-auto dark:text-white text-primary" />
            </Link>

            <button
              type="button"
              className="hidden p-0 float-end"
              id="vertical-hover">
              <i className="ri-record-circle-line" />
            </button>
          </div>
          <div className="px-3 mb-6">
            <MenuSearch />
          </div>
          <div
            id="scrollbar"
            className="px-2 pb-4 overflow-y-auto h-full max-h-full">
            <div className="simplebar-wrapper w-full">
              <ul className="menu w-full gap-2 font-semibold">
                {sidebarLinks.map((link, i) => (
                  <SidebarItem
                    item={link}
                    key={i}
                  />
                ))}
              </ul>
            </div>
            {/* Sidebar */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideDrawer;
