import { SvgProps } from "utils/types/app";

export const ChevronUp: React.FC<SvgProps> = (props) => {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.313 4.063H4.686v.625h5.625v-.625zM10.313 6.563H4.686v.625h5.625v-.625zM7.813 9.063H4.688v.624h3.125v-.624z"
        fill="#000"
      />
      <path
        d="M1.875 13.75l2.813-1.25L7.5 13.75l2.813-1.25 2.812 1.25V1.25H1.875v12.5zM2.5 1.875h10v10.913l-1.934-.86-.254-.112-.253.113L7.5 13.066l-2.559-1.137-.253-.113-.254.113-1.934.86V1.874z"
        fill="#000"
      />
    </svg>
  );
};
export const ChevronDown: React.FC<SvgProps> = (props) => {
  return (
    <svg
      width={17}
      height={18}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.50007 11.3322L3.0838 5.91592L2.58301 6.41672L8.50007 12.3338L14.4171 6.41672L13.9163 5.91592L8.50007 11.3322Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ChevronLeft: React.FC<SvgProps> = (props) => (
  <svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.2032 5.57209C14.5441 5.913 14.5441 6.46572 14.2032 6.80663L9.58272 11.4271L14.2032 16.0475C14.5441 16.3884 14.5441 16.9411 14.2032 17.282C13.8622 17.6229 13.3095 17.6229 12.9686 17.282L7.42227 11.7357C7.25182 11.5652 7.25182 11.2889 7.42227 11.1184L12.9686 5.57209C13.3095 5.23118 13.8622 5.23118 14.2032 5.57209Z"
      fill="currentColor"
    />
  </svg>
);
export const ChevronRight: React.FC<SvgProps> = (props) => (
  <svg
    width={17}
    height={17}
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M10.8323 8.50001L5.41602 13.9163L5.91681 14.4171L11.8339 8.50001L5.91681 2.58294L5.41602 3.08374L10.8323 8.50001Z"
      fill="currentColor"
    />
  </svg>
);
