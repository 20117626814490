import { classNames } from "utils/helper/helper";
import { Image, ImageProps } from "@nextui-org/react";

function NextUIImage(props: ImageProps) {
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...props}
      fallbackSrc={"/assets/img/placeholder.jpg"}
      classNames={{
        wrapper: classNames("bg-cover bg-center", props.classNames?.wrapper),
      }}
    />
  );
}

export default NextUIImage;
