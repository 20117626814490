import { alertMessage } from "components/toolkit/initial-state.component";
import validator from "validator";

type User = {
  email: string;
};

type ValidationErrors = {
  email?: string;
};

const validateForm = (user: User): ValidationErrors => {
  const errors: ValidationErrors = {};

  if (user.email === "") {
    errors.email = "Please email is required";
  }
  if (user.email && !validator.isEmail(user.email.toString())) {
    errors.email = "This must be of type 'email'";
  }
  return errors;
};

const isValidateForm = (user: User) => {
  const errors = {
    email: user.email === "" || !validator.isEmail(user.email.toString()),
  };
  return Object.values(errors).every((error) => !error);
};

export const registerUser = async (formData: FormData) => {
  const user = {
    email: String(formData.get("email")),
  };

  if (!isValidateForm(user)) {
    return {
      message: "Invalid inputs",
      errors: validateForm(user),
    };
  }
  const hostURL = process.env.REACT_APP_BACKEND_URL,
    apiKey = process.env.REACT_APP_API_KEY;
  const response = await fetch(hostURL! + "/api/v1/subscriber", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "X-Api-Key": apiKey!,
    },
    body: JSON.stringify(user),
  });

  const result = await response.json();
  // console.log(result);
  if (result.errors != null) return result;
  if (!result.success && result.message) {
    alertMessage("warn", result.message);
  }
  if (result.success && result.message) {
    const newsletterToast = document?.getElementById(
      "newsletter_toast"
    ) as HTMLDialogElement | null;

    if (newsletterToast) {
      // Open the dialog modal (with backdrop)
      newsletterToast.showModal(); // This automatically adds a backdrop
    }
  }
  return result;
};
