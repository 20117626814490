import { Card, CardBody, CardHeader } from "@nextui-org/react";
import FormInput from "../../form/form-input.component";
import { ProductValidationErrors } from "utils/helper/product-helper";
import { useSelector, useDispatch } from "react-redux";
import { selectProduct } from "store/product/product.selector";
import { ChangeEvent } from "react";
import { Product } from "utils/types/products";
import { updateCurrentProduct } from "store/product/product.actions";

function AddProductInventory({
  errors,
}: {
  errors: ProductValidationErrors | null;
}) {
  const product = useSelector(selectProduct),
    dispatch = useDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateCurrentProduct({ name: name as keyof Product, value }));
  };
  return (
    <Card
      className="py-4 lg:px-4 shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Inventory</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-4 space-y-2">
        <FormInput
          type="number"
          lang="en"
          min="0"
          name="current_stock"
          value={product.current_stock}
          label={`Quantity`}
          placeholder={`Type product quantity here. . .`}
          errorMessage={errors?.current_stock}
          onChange={handleChange}
        />
      </CardBody>
    </Card>
  );
}

export default AddProductInventory;
