import { classNames } from "utils/helper/helper";
import { Button } from "@nextui-org/react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { CustomArrowProps } from "react-slick";

export function SampleNextArrow(props: CustomArrowProps) {
  const { onClick } = props;
  return (
    <Button
      className={classNames(
        // className,
        "w-10 h-10 bg-white text-primary absolute top-[50%] -right-3 z-10"
      )}
      radius="full"
      isIconOnly
      onClick={onClick}>
      <BiChevronRight size={25} />
    </Button>
  );
}

export function SamplePrevArrow(props: CustomArrowProps) {
  const { onClick } = props;
  return (
    <Button
      className={classNames(
        // className,
        "w-10 h-10 bg-white text-primary absolute top-[50%] -left-3 z-10"
      )}
      radius="full"
      isIconOnly
      onClick={onClick}>
      <BiChevronLeft size={25} />
    </Button>
  );
}
export function SecondaryNextArrow(props: CustomArrowProps) {
  const { onClick } = props;
  return (
    <Button
      className={classNames(
        "w-10 h-10 bg-white text-primary absolute -bottom-[10%] right-28 md:right-12 lg:right-20 xl:right-28 z-10"
      )}
      variant="bordered"
      color="secondary"
      onClick={onClick}>
      Next
    </Button>
  );
}

export function SecondaryPrevArrow(props: CustomArrowProps) {
  const { onClick } = props;
  return (
    <Button
      className={classNames(
        "w-10 h-10 bg-white text-primary absolute -bottom-[10%] left-28 md:left-12 lg:left-20 xl:left-28 z-10"
      )}
      variant="bordered"
      color="secondary"
      onClick={onClick}>
      Prev
    </Button>
  );
}
