import { SvgProps } from "utils/types/app";

const TwitterSvg: React.FC<SvgProps> = (props) => {
  return (
    <svg
      width={46}
      height={45}
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1817_789)">
        <path
          d="M42.054 10.605a15.717 15.717 0 01-4.504 1.234A7.867 7.867 0 0041 7.5a15.672 15.672 0 01-4.98 1.903 7.841 7.841 0 00-13.361 7.151A22.265 22.265 0 016.496 8.361a7.815 7.815 0 00-1.06 3.943 7.84 7.84 0 003.487 6.527 7.815 7.815 0 01-3.552-.981v.098a7.847 7.847 0 006.29 7.689 7.894 7.894 0 01-3.543.135 7.847 7.847 0 007.326 5.447 15.738 15.738 0 01-11.608 3.247 22.181 22.181 0 0012.018 3.525c14.425 0 22.313-11.95 22.313-22.312 0-.338-.01-.679-.024-1.013a15.931 15.931 0 003.913-4.06l-.002-.001z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1817_789">
          <path
            fill="#fff"
            transform="translate(.5)"
            d="M0 0H45V45H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TwitterSvg;
