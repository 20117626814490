import { Button, Card, CardBody, CardHeader } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCategories } from "store/category/category.selector";
import { useState } from "react";
// import { ProductValidationErrors } from "utils/helper/product-helper";
import { updateCurrentProduct } from "store/product/product.actions";

import CustomSelect from "components/form/form-select.component";
import { Categories, Category } from "utils/types/category";
import { logger } from "lib/logger";
import { apiRequest } from "lib/api/apiClient";
import { productActions } from "store/product/product.slice";

function EditProductCategoryForm({
  setStep,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [selected, setSelected] = useState<Categories>([]);
  const [loading, setLoading] = useState(false);
  const categories = useSelector(selectAllCategories) || [],
    categoryOptions = categories?.map((i) => ({
      label: i.name,
      value: i.id,
    })),
    dispatch = useDispatch();
  const handleCategoryChange = (
    target: { value: number; label: string },
    index = 0
  ) => {
    const { value } = target;
    const category = categories.find((c) => c.id === value);
    if (category)
      setSelected((prev) => {
        // Create a copy of the previous array, up to the current index (excluding any elements after it)
        const truncatedSelected = prev.slice(0, index);

        // Add the new category at the specified index
        const updatedSelected = [...truncatedSelected, category];

        return updatedSelected;
      });
    if (index > 0) {
      const lastCategory = selected[index - 1];
      const newCategory = lastCategory.children.find((c) => c.id === value);
      if (newCategory) {
        setSelected((prev) => {
          // Create a copy of the previous array, up to the current index (excluding any elements after it)
          const truncatedSelected = prev.slice(0, index);

          // Add the new category at the specified index
          const updatedSelected = [...truncatedSelected, newCategory];

          return updatedSelected;
        });
      }
      //   logger.log("lastCategory:", lastCategory);
    }
  };
  const handleCategorySelect = async (category: Category) => {
    setLoading(true);
    try {
      const data = await apiRequest(
        `/api/v1/related-to-category/${category.slug}`
      );
      if (!data) return;
      logger.log(data);

      dispatch(productActions.setRelatedData(data));
      dispatch(
        updateCurrentProduct({
          name: "category_id",
          value: category.id,
        })
      );
      setStep(2);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  logger.log(selected);
  return (
    <Card
      className="py-4 lg:px-4 overflow-visible shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Category</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-4 space-y-2">
        <div className={""}>
          <CustomSelect
            label="Product Category"
            options={categoryOptions}
            // value={ctgyOption}
            // formatGroupLabel={formatGroupLabel}
            onChange={(value) => value != null && handleCategoryChange(value)}
          />
        </div>
        {selected.map((category, index) => (
          <div
            className={""}
            key={category.id}>
            {category.children.length > 0 ? (
              <CustomSelect
                label={`Product Category - ${category.name}`}
                options={category.children.map((i) => ({
                  label: i.name,
                  value: i.id,
                }))}
                // value={ctgyOption}
                // formatGroupLabel={formatGroupLabel}
                onChange={(value) =>
                  value != null && handleCategoryChange(value, index + 1)
                }
              />
            ) : (
              <div className="w-full centered pt-4">
                <Button
                  color="secondary"
                  className="w-full"
                  isLoading={loading}
                  isDisabled={loading}
                  onPress={() => handleCategorySelect(category)}>
                  Next
                </Button>
              </div>
            )}
          </div>
        ))}
      </CardBody>
    </Card>
  );
}

export default EditProductCategoryForm;
