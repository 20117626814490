import { SvgProps } from "utils/types/app";

const YoutubeSvg: React.FC<SvgProps> = (props) => {
  return (
    <svg
      width={46}
      height={45}
      viewBox="0 0 46 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#clip0_1817_791)">
        <path
          d="M41.143 12.184C42 15.525 42 22.5 42 22.5s0 6.975-.857 10.316c-.476 1.847-1.87 3.3-3.634 3.791-3.204.893-14.259.893-14.259.893s-11.05 0-14.26-.892c-1.771-.5-3.163-1.95-3.633-3.792C4.5 29.475 4.5 22.5 4.5 22.5s0-6.975.857-10.316c.476-1.847 1.87-3.3 3.634-3.791C12.2 7.5 23.25 7.5 23.25 7.5s11.055 0 14.26.893c1.771.498 3.163 1.95 3.633 3.79zM19.5 29.063L30.75 22.5 19.5 15.937v13.126z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1817_791">
          <path
            fill="#fff"
            transform="translate(.75)"
            d="M0 0H45V45H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default YoutubeSvg;
