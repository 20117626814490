import { Card, CardBody, CardHeader } from "@nextui-org/react";
import FormInput from "../../form/form-input.component";
import { ChangeEvent } from "react";
import FormSelect from "../../form/select.component";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrencies } from "store/data/data.selector";
import CustomSelect from "../../form/form-select.component";
import { selectProduct } from "store/product/product.selector";
import { Product } from "utils/types/products";
import { updateCurrentProduct } from "store/product/product.actions";
import { ProductValidationErrors } from "utils/helper/product-helper";
import { classNames } from "utils/helper/helper";

export interface Specification {
  key: string;
  value: string;
}

const discountTypes = [
  {
    label: "Flat",
    value: "amount",
  },
  {
    label: "Percent",
    value: "percent",
  },
];

function AddProductPricing({
  errors,
}: {
  errors: ProductValidationErrors | null;
}) {
  // const [taxClass, setTaxClass] = useState<string | null>(
  //   discountTypes[0].value
  // );
  const product = useSelector(selectProduct),
    dispatch = useDispatch();
  const currencies = useSelector(selectCurrencies),
    currencyOptions = currencies?.map((i) => ({ value: i.id, label: i.name })),
    selectedcurrency = currencyOptions?.find(
      (i) => i.value === product.currency_id
    );
  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    dispatch(updateCurrentProduct({ name: name as keyof Product, value }));
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateCurrentProduct({ name: name as keyof Product, value }));
  };
  const handleCurrencyChange = (target: { value: number; label: string }) => {
    const { value } = target;
    dispatch(
      updateCurrentProduct({
        name: "currency_id",
        value,
      })
    );
  };

  return (
    <Card
      className="py-4 lg:px-4 shadow"
      radius="md">
      <CardHeader className="pb-0 pt-2 px-4 flex-col items-start !z-0">
        <h4 className="font-bold text-large">Pricing</h4>
      </CardHeader>
      <CardBody className="overflow-visible py-4 space-y-2">
        <div className="grid lg:grid-cols-2 gap-3">
          <FormInput
            type="number"
            lang="en"
            min="0"
            step="0.01"
            name="price"
            label="Base Price"
            value={product.price}
            onChange={handleChange}
            placeholder={"Type Base Price here. . ."}
            errorMessage={errors?.price}
          />
          <div
            className={classNames(errors?.currency_id != null && "has-error")}>
            <CustomSelect
              options={currencyOptions}
              name="currency_id"
              label="Currency"
              value={selectedcurrency}
              className="h-12"
              onChange={(value) => value != null && handleCurrencyChange(value)}
            />
            {errors?.currency_id != null && (
              <div className="label">
                <span className="label-text-alt text-red-600">
                  {errors?.currency_id}
                </span>
              </div>
            )}
          </div>
          <FormSelect
            options={discountTypes}
            placeholder="Select a discount type"
            label="Discount Type"
            name="discount_type"
            value={
              product.discount_type == null ? undefined : product.discount_type
            }
            onChange={handleSelectChange}
          />
          <FormInput
            type="number"
            lang="en"
            min="0"
            step="0.01"
            name="discount"
            label={`Discount ${
              product.discount_type === "percent" ? "Precentage (%)" : "Amount"
            }`}
            placeholder={`Type discount ${
              product.discount_type === "percent" ? "precentage" : "amount"
            } . . .`}
          />
          {/* <FormSelect
            options={discountTypes}
            placeholder="Select a tax class"
            label="Tax Class"
            name="tax_class"
            defaultValue={taxClass == null ? undefined : taxClass}
            onChange={handleSelectChange}
          />
          <FormInput
            type="number"
            lang="en"
            min="0"
            step="0.01"
            name="tax"
            label={`VAT Amount ${taxClass === "percent" ? "(%)" : ""}`}
            placeholder={`Type VAT amount. . .`}
            onChange={handleChange}
          /> */}
        </div>
      </CardBody>
    </Card>
  );
}

export default AddProductPricing;
