import Footer from "components/navigation/footer";
import Header from "components/navigation/header";
import InitialState from "components/toolkit/initial-state.component";
import { Outlet } from "react-router-dom";
function Root() {
  return (
    <>
      <InitialState />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default Root;
