function EmptyComponent({ message = "" }) {
  return (
    <section className="centered flex-col gap-6">
      <div className="text-sm font-normal">No result </div>
      <svg
        width={184}
        height={203}
        className="w-1/2 h-auto lg:w-[184px] ml-6 text-[#002846] dark:text-white"
        viewBox="0 0 184 203"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M66.6274 10.0019L66.6284 10.0036L144.399 137.233L144.399 137.233C147.498 142.3 142.534 148.475 136.912 146.529L81.6426 127.393C81.6425 127.393 81.6424 127.393 81.6423 127.393C78.9581 126.463 76.1054 126.122 73.2776 126.392C70.4498 126.662 67.7131 127.537 65.2532 128.958L65.2515 128.959L16.4844 157.156C11.4019 160.092 5.40014 155.145 7.32403 149.588L55.2249 11.2347L55.2253 11.2335C55.6182 10.0973 56.3262 9.09633 57.2664 8.34725C58.2067 7.59816 59.3406 7.13187 60.5359 7.00277C61.7311 6.87367 62.9384 7.08707 64.017 7.6181C65.0955 8.14912 66.0009 8.97587 66.6274 10.0019Z"
          fill="#FFB800"
          stroke="#002846"
          strokeWidth="8.23949"
        />
        <path
          d="M59.1831 138.479C61.6799 138.416 67.1979 139.265 69.2952 143.16C67.4225 144.534 63.4898 147.992 62.7408 150.838C66.4859 154.022 74.1263 155.145 70.9803 160.388C68.2837 164.883 63.8642 169.959 51.5054 157.954C48.1345 154.68 46.4498 152.336 59.1831 138.479Z"
          fill="currentColor"
        />
        <path
          d="M85.9611 145.22C87.4378 143.206 92.2702 142.627 96.6349 143.348C96.5771 145.669 98.3655 150.811 100.16 153.144C104.969 152.126 110.528 146.766 112.755 152.46C114.664 157.342 115.98 163.942 98.9249 166.386C94.273 167.052 89.1456 163.768 85.9611 145.22Z"
          fill="currentColor"
        />
        <ellipse
          cx="56.8686"
          cy="98.3148"
          rx="7.36923"
          ry="13.8173"
          transform="rotate(4.94682 56.8686 98.3148)"
          fill="#002846"
        />
        <ellipse
          cx="85.0746"
          cy="97.3935"
          rx="7.36923"
          ry="13.8173"
          transform="rotate(3.24765 85.0746 97.3935)"
          fill="#002846"
        />
      </svg>
      <div className="max-w-[525px] text-center text-xl font-normal font-['Lato']">
        {message}
      </div>
    </section>
  );
}

export default EmptyComponent;
