import { Progress } from "@nextui-org/react";
import { classNames } from "utils/helper/helper";

function EditProductStepper({
  step,
  setStep,
}: {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}) {
  return (
    <>
      <div className="flex items-center w-full max-w-52">
        <div className="flex items-center flex-col pt-4 gap-2">
          <span
            role="button"
            onClick={() => setStep(1)}
            className={classNames(
              "flex items-center justify-center w-5 h-5 bg-secondary",
              "rounded-full lg:h-6 lg:w-6 shrink-0"
            )}>
            {step > 1 ? (
              <svg
                className="w-1 h-1 text-white lg:w-2 lg:h-2 dark:text-black"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 16 12">
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M1 5.917 5.724 10.5 15 1.5"
                />
              </svg>
            ) : (
              <span className="text-xs">1</span>
            )}
          </span>
          <span className="text-xs text-black dark:text-white">Category</span>
        </div>
        <Progress
          className="h-1"
          color="secondary"
          value={step > 2 ? 50 : 100}
        />
        <div className="flex items-center flex-col pt-4 gap-2">
          <span
            role="button"
            onClick={() => setStep(2)}
            className={classNames(
              "flex items-center justify-center w-5 h-5 text-xs ",
              "rounded-full lg:h-6 lg:w-6 shrink-0",
              step < 2
                ? "bg-default-300/50 text-black dark:text-white"
                : "bg-secondary text-white dark:text-black"
            )}>
            2
          </span>
          <span className="text-xs text-black dark:text-white inline whitespace-nowrap">
            Product Info
          </span>
        </div>
      </div>
    </>
  );
}

export default EditProductStepper;
