import EmptyComponent from "components/main/empty";
import { useSelector } from "react-redux";
import { selectAllActivity } from "store/activity/activity.selector";
import NotificationItem from "./item";

function NotificationList() {
  const notifications = useSelector(selectAllActivity);

  if (notifications == null || notifications?.length === 0)
    return (
      <div>
        {" "}
        <EmptyComponent message="No message found" />{" "}
      </div>
    );
  return (
    <div className="flex flex-col gap-4">
      {notifications.slice(0, 12).map((item) => (
        <NotificationItem item={item} />
      ))}
    </div>
  );
}

export default NotificationList;
