import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  useDisclosure,
  ModalFooter,
} from "@nextui-org/react";
import { Dispatch, SetStateAction } from "react";
import { PiPlus } from "react-icons/pi";
import { useSelector } from "react-redux";
import { selectTheme } from "store/theme/theme.selector";

type Props = {
  hasColor: boolean;
  setHasColor: Dispatch<SetStateAction<boolean>>;
  hasAttribute: boolean;
  setHasAttribute: Dispatch<SetStateAction<boolean>>;
};

function AddVariant({
  hasColor,
  setHasColor,
  hasAttribute,
  setHasAttribute,
}: Props) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const theme = useSelector(selectTheme);
  return (
    <>
      <Button
        color={theme != null && theme === "dark" ? "secondary" : "primary"}
        variant="bordered"
        onPress={onOpen}
        className="w-fit">
        <PiPlus size={20} />
        Add Variant
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="top-center">
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Variant
              </ModalHeader>
              <ModalBody>
                <Button
                  color={
                    theme != null && theme === "dark" ? "secondary" : "primary"
                  }
                  className="disabled:opacity-40 disabled:hover:opacity-40"
                  variant="bordered"
                  disabled={hasAttribute}
                  onPress={() => setHasAttribute(true)}>
                  <PiPlus size={20} />
                  Add Attribute
                </Button>
                <Button
                  color={
                    theme != null && theme === "dark" ? "secondary" : "primary"
                  }
                  variant="bordered"
                  disabled={hasColor}
                  className="disabled:opacity-40 disabled:hover:opacity-40"
                  onPress={() => setHasColor(true)}>
                  <PiPlus size={20} />
                  Add Color
                </Button>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="flat"
                  onPress={onClose}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddVariant;
