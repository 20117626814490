import CheronRight from "assets/svgs/cheronright.svg";
import PoliciesLG from "components/policies/slider-lg";
import PoliciesSM from "components/policies/slider.sm";
import { Link, useLoaderData } from "react-router-dom";
import { Policy } from "utils/types/policy";

function PolicyPage() {
  const policy = useLoaderData() as Policy;

  return (
    <main className="w-full overflow-x-hidden bg-white">
      <section className="relative pb-40 pt-24 md:pb-20 lg:pb-20 py-12 lg:pt-28 px-6 xl:px-24 space-y-6 text-[#6C6C72] dark:text-gray-400">
        <ol className="flex items-center whitespace-nowrap min-w-0">
          <li className="text-sm">
            <Link
              className="flex items-center text-primary hover:text-primary dark:text-primary truncate"
              to="/">
              Home
              <CheronRight className="h-3 w-3 flex-shrink-0 mx-3 overflow-visible text-gray-300 dark:text-gray-300 rtl:rotate-180" />
            </Link>
          </li>
          <li
            className="text-sm text-gray-500 hover:text-primary dark:text-white/70 "
            aria-current="page">
            Policy
          </li>
        </ol>
        <div className="centered lg:px-6 w-full">
          <div className="flex flex-col w-full justify-center gap-4 md:gap-6 lg:gap-12 lg:py-12">
            <div className="grid grid-cols-1 lg:grid-cols-12 gap-9">
              <div className="hidden lg:block col-span-4">
                <PoliciesLG />
              </div>
              <div className="lg:col-span-8">
                <h3 className="text-primary text-2xl lg:text-[32px] font-black mb-4">
                  {policy.title}
                </h3>
                <div dangerouslySetInnerHTML={{ __html: policy.body }}></div>
              </div>
            </div>
            <PoliciesSM />
          </div>
        </div>
      </section>
    </main>
  );
}

export default PolicyPage;
