import CustomSelect from "components/form/form-select.component";
import { selectColors } from "store/color/color.selector";
import { updateCurrentProduct } from "store/product/product.actions";
import { selectProduct } from "store/product/product.selector";
import { ColorImage } from "utils/types/products";
import { Dispatch, SetStateAction } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiValue, Options } from "react-select";

type Props = {
  setHasColor: Dispatch<SetStateAction<boolean>>;
};
function AddColors({ setHasColor }: Props) {
  const product = useSelector(selectProduct),
    dispatch = useDispatch();
  const colors = useSelector(selectColors),
    options = !colors
      ? []
      : colors.map((color) => ({ label: color.name, value: color.id })),
    selectedColorIds = product.colors,
    selected = options.filter((option) =>
      selectedColorIds.includes(option.value)
    ),
    selectedColor = colors?.filter((option) =>
      selectedColorIds.includes(option.id)
    );

  const handleSelect = (
      option: MultiValue<{
        label: string;
        value: number;
      }>
    ) => {
      const optionValues = option.map((i) => i.value),
        images: ColorImage[] = optionValues.map((i) => ({
          color_id: i,
          images: [],
        }));
      dispatch(
        updateCurrentProduct({
          name: "colors",
          value: optionValues,
        })
      );
      dispatch(
        updateCurrentProduct({
          name: "color_images",
          value: images,
        })
      );
    },
    handleCancelColor = () => {
      dispatch(
        updateCurrentProduct({
          name: "colors",
          value: [],
        })
      );
      dispatch(
        updateCurrentProduct({
          name: "color_images",
          value: [],
        })
      );
      setHasColor(false);
    };
  return (
    <div>
      <div>
        <div className="label">
          <span className="label-text">Color</span>
        </div>
        <div className="flex gap-3">
          <CustomSelect
            name="colors[]"
            options={options}
            value={selected}
            onChange={(option) => handleSelect(option)}
            className="w-full h-full"
            isSearchable
            isMulti
          />
          <button
            className="btn btn-square btn-soft-danger"
            onClick={handleCancelColor}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-6 lg:grid-cols-8 gap-3 mt-3">
        {selectedColor?.map((color) => (
          <div
            className="rounded-lg h-10 w-full"
            key={color.id}
            style={{
              backgroundColor: color.code,
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default AddColors;
