import { Outlet, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";

ReactGA.initialize("G-MBE8B202X1"); // Replace with your actual GA4 tracking ID

function Analytics() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
  return <Outlet />;
}

export default Analytics;
