// import { makeId, slugify } from "./helper";

import { Product } from "../types/products";

export const sizes = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop: 2560,
  },
  devices = {
    mobileS: `(min-width: ${sizes.mobileS}px)`,
    mobileM: `(min-width: ${sizes.mobileM}px)`,
    mobileL: `(min-width: ${sizes.mobileL}px)`,
    tablet: `(min-width: ${sizes.tablet}px)`,
    laptop: `(min-width: ${sizes.laptop}px)`,
    laptopL: `(min-width: ${sizes.laptopL}px)`,
    desktop: `(min-width: ${sizes.desktop}px)`,
  },
  THEME_KEY = "theme",
  USER_IP_AND_LOCATION = "_cusorcart-user-ip-and-location",
  AUTH_TOKEN = "_x-cusorcart-auth-token",
  REDIRECT_URI = "cusorcart-redirect",
  ipURL = "https://api.ipify.org?format=json",
  getLocationURL = (ip: string) => `https://ipwhois.app/json/${ip}`,
  hostURL = "http://localhost:8000",
  homeURL = "https://cusorcart.com",
  vendor_link = "https://vendor.cusorcart.com",
  blogHome = "https://hub.cusorcart.com";
const localTheme =
  typeof window !== "undefined" ? localStorage.getItem(THEME_KEY) : null;
export const htmlClass =
    typeof window !== "undefined"
      ? (localTheme == null &&
          window.matchMedia("(prefers-color-scheme: dark)").matches) ||
        localTheme === "dark"
        ? "dark"
        : "light"
      : "",
  overviewOptions = [
    {
      label: "All Time",
      value: "all_time",
    },
    {
      label: "12 Months",
      value: "12_months",
    },
    {
      label: "30 Days",
      value: "30_days",
    },
    {
      label: "7 Days",
      value: "7_days",
    },
    {
      label: "24 Hours",
      value: "24_hours",
    },
  ],
  defaultProductData: Product = {
    name: "",
    slug: "",
    added_by: "vendor",
    user_id: 0,
    category_id: 0,
    category_tags: [],
    brand_id: 0,
    thumbnail_img: null,
    photos: [],
    color_images: [],
    description: "",
    specifications: null,
    currency_id: 0,
    price: 0,
    weight: 0,
    weight_unit: "",
    width: 0,
    length: 0,
    height: 0,
    tags: [],
    current_stock: 0,
    min_qty: 1,
    low_stock_quantity: null,
    discount: null,
    discount_type: null,
    discount_start_date: null,
    discount_end_date: null,
    meta_title: null,
    meta_description: null,
    meta_img: null,
    barcode: null,
    refundable: 0,
    todays_deal: 0,
    published: 0,
    approved: 0,
    cash_on_delivery: 0,
    featured: 0,
    seller_featured: 0,
    colors: [],
    attributes: [],
    variations: null,
  },
  weight_units = [
    { value: "kilogram", label: "Kilogram" },
    { value: "gram", label: "gram" },
    { value: "milligram", label: "milligram" },
    { value: "metric ton", label: "metric ton" },
    { value: "long ton", label: "long ton" },
    { value: "short ton", label: "short ton" },
    { value: "pound", label: "pound" },
    { value: "ounce", label: "ounce" },
  ],
  id_types = [
    {
      value: "drivers-license",
      label: "Driver's License / Government-issued photo ID",
    },
    {
      value: "national-id",
      label: "National Identity Card",
    },
    {
      value: "passport",
      label: "Passport",
    },
    {
      value: "SSN",
      label: "Social security number",
    },
    {
      value: "pan-card",
      label: "PAN card",
    },
    {
      value: "voter-id-card",
      label: "Voter ID Card",
    },
  ],
  aboutCheckList = [
    {
      title: "Built on trust",
      content:
        "At Cusorcart, trust is the cornerstone of our marketplace. We prioritize your security and satisfaction, ensuring every transaction is safe and reliable. Our commitment to transparency and integrity means you can shop with confidence, knowing that your interests are always protected.",
    },
    {
      title: "Easy to use",
      content:
        "Navigating Cusorcart is a breeze, thanks to our user-friendly interface. Whether you're a tech-savvy shopper or new to online shopping, our platform is designed to make your experience smooth and enjoyable. From searching for products to completing purchases, every step is intuitive and straightforward.",
    },
    {
      title: "Versatile",
      content:
        "Cusorcart offers an extensive range of products to meet your every need. Our versatile platform caters to all categories, ensuring you can find exactly what you're looking for. Whether it's electronics, fashion, home goods, or more, Cusorcart has it all in one convenient place.",
    },
  ];
