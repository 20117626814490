import { LoaderFunctionArgs } from "react-router-dom";
import { getHeaders } from "utils/header";
import { getEnvProps } from "utils/helper/server-helper";
import { Policy } from "utils/types/policy";
const getPolicyBySlug = async (slug: string): Promise<Policy | null> => {
  const envProps = await getEnvProps();
  const headers = await getHeaders();

  let policy: Policy | null = null;

  try {
    const req = await fetch(`${envProps.base_url}/api/v1/get-policy/${slug}`, {
        headers,
      }),
      res = await req.json();

    if (res.success != null && res.success) {
      return res.data;
    } else {
      throw new Error("Error 404, policy not found.");
    }
  } catch (error) {
    console.error("Error fetching policy:", error);
    // throw new Error("Error fetching policy");
  }

  return policy;
};

export const policyLoader = async ({ params }: LoaderFunctionArgs) => {
  const { slug } = params;
  if (slug) {
    const policy = await getPolicyBySlug(slug);
    if (policy == null) {
      throw new Response("Error 404, policy not found.", { status: 404 });
    }

    return policy;
  }
  return null;
};
