import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, RouteObject, useLocation } from "react-router-dom";
import { selectCurrentUser, selectIsLoggedIn } from "store/user/user.selector";
import { authLinks } from "utils/helper/links";
import { REDIRECT_URI } from "utils/helper/states";
import NotPermitted from "./forbidden/page";
import ErrorPage from "components/error/error.component";
import { ticketLoader } from "loaders/ticket";
import Preloader from "components/preloader";
import NotificationPage from "./dashboard/notification";
import EditProduct from "./dashboard/product/products/edit";
import { productLoader } from "loaders/products";

const VerifyEmail = lazy(() => import("./auth/verify"));
const CompleteRegistration = lazy(() => import("./auth/complete-registration"));

const DashboardHome = lazy(() => import("./dashboard"));
const ProfilePage = lazy(() => import("./dashboard/profile"));
const Products = lazy(() => import("./dashboard/product/products"));
const ProductsReviews = lazy(
  () => import("./dashboard/product/products-reviews")
);
const ProductsReport = lazy(() => import("./dashboard/reports/products"));
const ProductsWishlist = lazy(
  () => import("./dashboard/reports/product-wishlist")
);
const SupportPage = lazy(() => import("./dashboard/support"));
const AddProduct = lazy(
  () => import("./dashboard/product/products/add-product")
);
const KycPage = lazy(() => import("./dashboard/kyc"));
const AddTicket = lazy(() => import("./dashboard/support/add"));
const ViewResponses = lazy(() => import("./dashboard/support/replies"));

function ProtectedRoutes() {
  const isLoggedIn = useSelector(selectIsLoggedIn),
    user = useSelector(selectCurrentUser);
  const location = useLocation();

  // Check if user is logged in and is a vendor
  if (isLoggedIn && user != null) {
    return user.type !== "vendor" ? <NotPermitted /> : <Outlet />;
  }

  // Store the full URI including search (query params) and hash (fragments)
  const fullUri = `${location.pathname}${location.search}${location.hash}`;
  sessionStorage.setItem(REDIRECT_URI, fullUri);

  // Redirect to the login page
  return (
    <Navigate
      to={authLinks.login}
      replace
    />
  );
}

export const protectedRoutes: RouteObject[] = [
  {
    path: "/verify-email",
    element: <VerifyEmail />,
  },
  {
    path: "/auth/complete-registration",
    element: <CompleteRegistration />,
  },
  // dashboard
  {
    path: "/dashboard",
    element: <DashboardHome />,
  },
  {
    path: "/dashboard/profile",
    element: <ProfilePage />,
  },
  {
    path: "/dashboard/product/products",
    element: <Products />,
  },
  {
    path: "/dashboard/product/products/add-product",
    element: <AddProduct />,
  },

  {
    path: "/dashboard/product/products/:slug",
    element: (
      <Suspense fallback={<Preloader />}>
        <EditProduct />
      </Suspense>
    ),
    loader: productLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard/product/products-reviews",
    element: <ProductsReviews />,
  },
  {
    path: "/dashboard/reports/products",
    element: <ProductsReport />,
  },
  {
    path: "/dashboard/reports/product-wishlist",
    element: <ProductsWishlist />,
  },
  {
    path: "/dashboard/notifications",
    element: <NotificationPage />,
  },
  {
    path: "/dashboard/support/tickets",
    element: <SupportPage />,
  },
  {
    path: "/dashboard/support/add-ticket",
    element: <AddTicket />,
  },
  //
  {
    path: "/dashboard/support/tickets/:code",
    element: (
      <Suspense fallback={<Preloader />}>
        <ViewResponses />
      </Suspense>
    ),
    loader: ticketLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/dashboard/kyc",
    element: <KycPage />,
  },
];

export default ProtectedRoutes;
