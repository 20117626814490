import { ImageData } from "utils/types/gallery";
import { createSlice } from "@reduxjs/toolkit";

export type GalleryState = {
  readonly data: ImageData[] | null;
};

const INITIAL_STATE: GalleryState = {
  data: null,
};
const gallerySlice = createSlice({
  name: "gallery",
  initialState: INITIAL_STATE,
  reducers: {
    setGallery(state, action) {
      state.data = action.payload;
    },
  },
});

export const galleryActions = gallerySlice.actions;
export default gallerySlice;
